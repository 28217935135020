<template>
  <v-form class="position-relative" v-model="valid">
    <block v-if="loading"></block>

    <v-row class="ml-2 mr-2">
      <v-col cols="12" class="tabsNh">
        <template v-if="user">
          <v-col cols="12" md="12" class="d-flex justify-space-between">
            <v-btn small color="error" @click.stop="deleteDialog = true" dense>
              <v-icon> mdi-delete </v-icon>
            </v-btn>
            <v-btn small class="btnsave" @click.prevent="updateUser" dense>
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </v-col>
        </template>
        <v-col v-else cols="12" md="12" class="d-flex justify-end">
          <v-btn small class="btnsave" @click.prevent="saveUser" dense>
            <v-icon> mdi-content-save </v-icon>
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
    <v-row style="margin-top: 1.8rem; margin-bottom: 1rem" class="ml-2 mr-2">
      <v-col cols="12" md="2">
        <div class="box">
          <div style="display: flex; justify-content: center; width: 80%; margin-left: 10%">
            <v-img :src="url" class="preview-avatar"></v-img>
          </div>
          <v-file-input @change="Preview_image" v-model="userimage" ref="userimage" :rules="[maxFileSize]"
            label="Foto usuario" prepend-icon="mdi-camera" placeholder="Examinar">
          </v-file-input>
        </div>
        <div class="box">
          <div style="justify-content: center; display: flex; width: 80%; margin-left: 10%">
            <v-img :src="urlsello" class="preview-sello" />
          </div>
          <v-file-input @change="Preview_sello" v-model="sello" ref="sello" :rules="[maxFileSize]" label="Firma/sello"
            placeholder="Firma/sello" prepend-icon="mdi-camera">
          </v-file-input>
        </div>
        <div class="d-flex" style="justify-content: center">
          <v-btn class="btnsave" v-if="user" @click="ChangePasswordModal = true">
            Cambiar contraseña
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="10">
        <div class="box">
          <v-row>
            <v-col cols="12" md="2" style="margin-top: 1rem; margin-bottom: 1rem">
              <h3>Datos Principales</h3>
            </v-col>
            <v-col cols="12" md="2" v-if="$store.getters['rolesUser/isAdminOrManager']">
              <v-checkbox v-model="form.status" :label="`Estado: ${form.status ? 'activo' : 'inactivo'}`"></v-checkbox>
              <!--  <v-select outlined dense  :items="['active', 'inactive']" label="Estado*" v-model="form.status" attach
                                :rules="[v => !!v || $t('required')]" required dense></v-select> -->
            </v-col>
            <v-col cols="12" md="2" v-if="$store.getters['rolesUser/isAdminOrManager']">
              <v-checkbox v-model="form.checkedContract" label="En planilla"></v-checkbox>
            </v-col>
            <v-col cols="12" md="2" v-if="$store.getters['rolesUser/isAdminOrManager']">
              <v-checkbox v-model="form.webAccess" label="Acceso web"></v-checkbox>
            </v-col>
            <v-col cols="12" md="2" v-if="$store.getters['rolesUser/isAdminOrManager']">
              <v-checkbox v-model="form.assignSeller" label="Asignarlo vendedor"></v-checkbox>
            </v-col>
            <!-- <v-col v-else cols="12" md="4" class="d-flex justify-md-end justify-center">
              <v-btn class="btn-add btn-add-seller" @click="selectSellerDialog = true">
                <v-icon class="mr-5">mdi-card-account-details</v-icon>
                Selecionar vendedor
              </v-btn>
            </v-col> -->

            <v-col v-if="!user" cols="12" sm="12" md="12">
              <label>Nota: los apartados con (*) son datos requeridos</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.firstName" :rules="required" label="Nombres*"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.lastName" :rules="required" label="Apellidos*"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.email" label="Correo eléctronico"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select outlined dense :items="['Masculino', 'Femenino']" label="Sexo" v-model="form.gender"
                attach></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.mobile" label="Celular"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <date-picker label="Fecha nacimiento" @dateSelected="(param) => (form.birthday = param)"
                :value="form.birthday" v-model="form.birthday"></date-picker>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.contact" label="Contacto emergencia"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.phoneNumber" label="Teléfono emergencia"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.dui" label="DUI*" :rules="[(v) => !!v || $t('required')]"
                required></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete outlined dense :items="countryList" attach item-text="name" v-model="form.country" required
                :rules="required" @change="getStates" label="Pais*"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete outlined dense v-if="form.country == 'El Salvador'" attach :items="departments"
                item-text="Valores" item-value="Codigo" :rules="[(v) => !!v || $t('required')]" required
                v-model="form.department" label="Estado/Departamento*"></v-autocomplete>
              <v-autocomplete outlined dense attach v-else :items="stateList" item-text="name" @change="getCities"
                v-model="form.department" label="Estado/Departamento*" :rules="[(v) => !!v || $t('required')]" required>
                <template v-slot:no-data>
                  <div class="px-4">{{ noDataLabelStates }}</div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete outlined dense :items="municipalities" attach item-text="Valores" item-value="Codigo"
                v-if="form.country == 'El Salvador'" :readonly="!form.department" v-model="form.municipality"
                label="Ciudad*" :rules="[(v) => !!v || $t('required')]" required></v-autocomplete>
              <v-autocomplete outlined dense attach v-else :items="cityList" item-text="name"
                :rules="[(v) => !!v || $t('required')]" required :readonly="!form.department" v-model="form.municipality"
                label="Ciudad*">
                <template v-slot:no-data>
                  <div class="px-4">{{ noDataLabelCities }}</div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-if="$store.getters['rolesUser/isAdminOrManager']">
            <v-col cols="12" class="mt-4" style="margin-bottom: 1rem">
              <h3>Datos de cuenta</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" v-if="$store.getters['rolesUser/isAdminOrManager']">
              <v-text-field outlined dense v-model="form.username" maxlength="15" :label="$t('vwuser_username')"
                :rules="[(v) => !!v || $t('required')]" required></v-text-field>
            </v-col>

            <v-col cols="12" md="3" v-if="$store.getters['rolesUser/isAdminOrManager']">
              <span class="fixing">
                <v-autocomplete outlined dense :items="businessList" item-text="BusinessName" item-value="ID_Business"
                  attach :rules="[(v) => !!v || $t('required')]" label="Negocio del usuario*" v-model="form.userbusiness"
                  required></v-autocomplete>
              </span>
            </v-col>
            <v-col v-if="$store.getters['rolesUser/isAdminOrManager']" cols="12" md="3" class="d-flex align-end">
              <span class="fixing">
                <v-autocomplete outlined dense :items="branchesList" item-text="BranchName" item-value="ID_Branch"
                  label="Sucursal del usuario*" attach v-model="form.userbranch" required @click="fillInBranches()"
                  :rules="[(v) => !!v || $t('required')]"></v-autocomplete>
              </span>
            </v-col>
            <v-col v-if="type_user == 'ROLE_ADMIN'" cols="12" md="3" class="d-flex align-end">
              <v-autocomplete outlined dense :items="['Administrador de negocio', 'Usuario']" label="Tipo de usuario*"
                attach :rules="[(v) => !!v || $t('required')]" v-model="form.typeuser" required></v-autocomplete>
            </v-col>
            <v-col v-if="type_user == 'ROLE_ROOT'" cols="12" md="3" class="d-flex align-end">
              <span class="fixing">
                <v-autocomplete outlined dense :items="[
                  'Administrador del sistema',
                  'Administrador de negocio',
                  'Usuario',
                ]" label="Tipo de usuario" attach v-model="form.typeuser" required
                  :rules="[(v) => !!v || $t('required')]"></v-autocomplete>
              </span>
            </v-col>
            <v-col cols="12" md="3" v-if="!user">
              <span class="fixing">
                <v-text-field outlined dense type="password" v-model="form.password" :label="$t('vwuser_password')"
                  :rules="[(v) => !!v || $t('required')]" required></v-text-field>
              </span>
            </v-col>

            <v-col cols="12" md="3" v-if="$store.getters['rolesUser/isAdminOrManager']">
              <span class="fixing">
                <v-autocomplete outlined dense :items="[
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                  '13',
                  '14',
                  '15',
                  '16',
                  '17',
                  '18',
                  '19',
                  '20',
                ]" label="Punto de venta *" attach v-model="form.pointsale" required
                  :rules="[(v) => !!v || $t('required')]"></v-autocomplete>
              </span>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="ChangePasswordModal" width="60%">
      <div class="white pa-4">
        <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
          <v-btn small class="btndelete" @click.prevent="ChangePasswordModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row style="margin-top: 0.3rem">
          <v-col v-if="user" cols="12" md="4">
            <v-text-field outlined dense type="password" v-model="form.oldPassword"
              :label="$t('vwuser_oldpassword')"></v-text-field>
          </v-col>
          <v-col v-if="user" cols="12" md="4">
            <v-text-field outlined dense type="password" v-model="form.password"
              :label="$t('vwuser_newpassword')"></v-text-field>
          </v-col>
          <v-col v-else cols="12" md="4">
            <v-text-field outlined dense :rules="[(v) => !!v || $t('required')]" type="password" v-model="form.password"
              :label="$t('vwuser_password')"></v-text-field>
          </v-col>
          <v-col v-if="user" cols="12" md="4">
            <v-btn class="btnsave" @click="
              ChangePasswordModal = false;
            updateUser();
            ">
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="290">
      <delete-user-dialog :data="user" @close="closeDialog"></delete-user-dialog>
    </v-dialog>

    <v-dialog v-model="selectSellerDialog" max-width="70%">
      <v-card class="background-card">
        <GeneralFilter class="background border-box" :filterEndpoint="$API.sellers.getAllSellersFiltered" :search="search"
          @emptyFilter="getAllSellers" @filtered="handleFilter">
          <DxDataGrid class="isScrolledByGeneralFilter" :data-source="sellers" key-expr="ID_Seller"
            :show-row-lines="showRowLines" :show-borders="showBorders" :row-alternation-enabled="rowAlternationEnabled"
            @selection-changed="selectedSeller">
            <DxScrolling row-rendering-mode="virtual" />
            <DxSelection mode="single" />
            <DxPaging :page-size="10" />
            <DxColumn data-field="ID_Seller" caption="ID"></DxColumn>
            <DxColumn data-field="FirstName" caption="Nombres"></DxColumn>
            <DxColumn data-field="LastName" caption="Apellidos"></DxColumn>
            <DxColumn data-field="Email" caption="Correo"></DxColumn>
          </DxDataGrid>
        </GeneralFilter>
        <div class="background-card d-flex justify-end mr-10 pb-5">
          <v-btn class="btn-add" @click="selectSeller">Selecionar</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>
  </v-form>
</template>

<script>
import Vue from "vue";
import DatePicker from "../../../../components/DatePicker";
import Block from "../../../../components/Block";
import DeleteUserDialog from "./DeleteUserDialog";
import Alerts from "@/components/Alerts";
import { mapGetters, mapState } from "vuex";
import GeneralFilter from "../../../../components/GeneralFilter.vue";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxPaging,
  DxSelection,
} from "devextreme-vue/data-grid";
export default {
  name: "ManageUser",
  components: {
    DeleteUserDialog,
    Block,
    DatePicker,
    Alerts,
    GeneralFilter,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxPaging,
    DxSelection,
  },
  props: ["user", "win"],
  data: () => ({
    validSeller: true,
    ChangePasswordModal: false,
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    form: { status: true },
    valid: false,
    tab: null,
    fieldRules: [(v) => !!v || "Campo obligatorio"],
    nameRules: [(v) => !!v || "Name is required"],
    mobileRules: [(v) => !!v || "Mobile is required"],
    userNameRules: [(v) => !!v || "User name is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    url: null,
    userimage: null,
    loading: false,
    sello: null,
    urlsello: null,
    deleteDialog: false,
    sellofname: null,
    businessList: [],
    branchesList: [],
    requiredRule: [(value) => !!value || "Required."],
    type_user: null,
    selectSellerDialog: false,
    business_user: null,
    stateList: [],
    cityList: [],
    noDataLabelStates: "Seleccione un país",
    noDataLabelCities: "No hay ciudades disponibles",
    required: [(v) => !!v || "Campo requerido"],
    sellers: null,
    showRowLines: true,
    showBorders: true,
    rowAlternationEnabled: true,
    search: {
      filter: null,
    },
    selectedItems: null,
    validImageSize: true,
    mainRoleConfig: [
    'Administrador del sistema',
    'Administrador de negocio',
    'Usuario',
    ]
  }),
  mounted() {
    
    //load the data
    this.getStates();
    this.getCities();
    
    if (!this.user) {
      this.form.webAccess = true;
      this.sysParametersDefault.forEach((element) => {
        if (element.ParameterName == "country") {
          this.form.country = element.ParameterValue;
        } else if (element.ParameterName == "department") {
          this.form.department = element.ParameterValue;
        } else if (element.ParameterName == "city") {
          this.form.municipality = element.ParameterValue;
        }
      }); 
    }
   
    this.type_user = JSON.parse(localStorage.getItem("user")).roles[0];
    this.business_user = JSON.parse(localStorage.getItem("user")).business;
    
    this.userimage = this.user ? ( this.user.ProfilePicPath ? this.user.ProfilePicPath  : null ) : null;
    this.sello = this.user?.sello ? this.user.sello : null;
    this.url = this.userimage ? this.userimage : null;
    this.urlsello = this.sello ? this.sello : null;

    if (this.user) {
      this.getAllSellers();

      this.form.typeuser = this.mainRoleConfig[ this.user.mainRole - 1 ];
      for (var el in this.user) {
        if (el == "UserName") {
          Vue.set(this.form, "username", this.user[el]);
        } else if (el == "EmergencyContact") {
          Vue.set(this.form, "contact", this.user[el]);
        } else if (el == "EmergencyContactPhone") {
          Vue.set(this.form, "phoneNumber", this.user[el]);
        } else if (el == "DateOfBirth") {
          Vue.set(this.form, "birthday", this.user[el]);
        } else if (el == "HasContract") {
          Vue.set(this.form, "checkedContract", this.user[el]);
        } else if (el == "ID_Seller") {
          Vue.set(this.form, "assignSeller", this.user[el]);
        } else if (el == "WebAccess") {
          Vue.set(this.form, "webAccess", this.user[el]);
        } else if (el == "RolesGrid") {
          if (this.user[el][0]["ID_Role"] == 1) {
            Vue.set(this.form, "typeuser", "Administrador del sistema");
          } else if (this.user[el][0]["ID_Role"] == 2) {
            Vue.set(this.form, "typeuser", "Administrador de negocio");
          } else {
            Vue.set(this.form, "typeuser", "Usuario");
          }
        } else if (el == "ID_Business") {
          Vue.set(this.form, "userbusiness", parseInt(this.user[el]));
          console.log("negocio y branch ", this.user[el], this.form.userbusiness);
        } else if (el == "ID_Branch") {
          Vue.set(this.form, "originaluserbranch", parseInt(this.user[el]));
          Vue.set(this.form, "userbranch", parseInt(this.user[el]));
          console.log("negocio y branch ", this.user[el], this.form.userbranch);
        } else if (el == "PointSale") {
          Vue.set(this.form, "pointsale", this.user[el].toString());
        } else {
          Vue.set(this.form, el[0].toLowerCase() + el.slice(1), this.user[el]);
        }

        delete this.form.password;
      }
    }
    this.fillInBussiness();
    this.fillInBranches();

   
    
  },
  watch: {
    /* 'form.username': {
      deep: true,
      handler() {
        this.fillInBussiness();
      },
      inmmediate: true,
    }, */
    'form.userbusiness': {
      deep: true,
      handler() {
        this.fillInBranches();
      },
      inmediate: true,
    }
  },
  computed: {
    ...mapGetters(["getCatalogMH"]),
    ...mapState("sysparameters", {
      sysParametersDefault: (state) => state.parameters,
    }),
    departments() {
      return this.getCatalogMH("Departamento");
    },
    municipalities() {
      const municipalities = this.getCatalogMH("Municipio");
      if (this.form.department) {
        const department = municipalities.filter(
          (mun) => mun.Departamento == this.form.department
        );
        return department;
      }
      return [];
    },
    countryList() {
      return this.$store.getters.getCountries;
    },
    checkcountry() {
      if (this.form.country == "El Salvador") return true;
      else return false;
    },
  },
  methods: {
    handleFilter(response) {
      this.sellers = response;
    },
    getAllSellers() {
      this.$API.sellers.getAllSellers().then((response) => {
        console.log(response);
        this.sellers = response;
      });
    },
    async getStates() {
      if (this.form.country == "El Salvador") return [];
      if (this.form.country) {
        this.noDataLabelStates = "Cargando...";
        this.stateList = [];

        this.stateList = await this.$API.general.getStates(this.form.country);
        console.log(this.stateList);
        this.noDataLabelStates = "No hay Estados disponibles";
      }
    },
    async getCities() {
      if (this.form.country == "El Salvador") return [];
      console.log(this.form.department);
      if (this.form.department) {
        this.noDataLabelCities = "Cargando...";
        this.cityList = [];

        this.cityList = await this.$API.general.getCities(this.form.department);
        console.log(this.cityList);
        this.noDataLabelCities = "No hay Ciudades disponibles";
      }
    },

    closeAlert() {
      this.alert.show = false;

      if (this.alert.options === "usereload") {
        this.$store.dispatch("reloadData", "users");
        this.$store.dispatch("addWindow", {
          name: "UserList",
          component: "UserList",
          unique: false,
        });
      }
    },
    acceptAlert() {
      this.alert.show = false;
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.userimage);
    },
    Preview_sello() {
      this.sellofname = this.sello.name;
      this.urlsello = URL.createObjectURL(this.sello);
    },
    async fillInBussiness() {
      const id = JSON.parse(localStorage.getItem("user")).businessid;

      if(id && this.type_user == "ROLE_ADMIN") {
        await this.$API.business.FindOne(id).then((response) => {
          if(typeof(response) == 'object') {
            this.businessList = [ response ];
          }
          else {
            this.businessList = response;
          }
        });
      }
      else if(this.business_user != "none" && this.type_user == "ROLE_ADMIN") {
        await this.$API.business.businessListFiltered({ filter: this.business_user}).then((response) => {
          this.businessList = response;
        });
      }
      else {
        await this.$API.business.businessListHistory().then((response) => {
          //this.unfilteredBusinessList = response; // Store unfiltered data
          //this.filterHandler(this.unfilteredBusinessList); // Apply filtering
          this.businessList = response;
        });
      }

      if(this.businessList.length == 1) {
        this.form.userbusiness = this.businessList[0].ID_Business;
        await this.fillInBranches();
      }
      
    },
    fillInBranches() {
      this.$API.branches.findBranches(this.form.userbusiness).then((data) => {
        this.branchesList = data;
      });
    },
    saveUser() {
      if (this.checkImageSize()) return;

      if (!this.valid) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe llenar todos los campos requeridos"
        );
        return;
      }
      this.loading = true;
      let formData = new FormData();
      if (this.type_user == "ROLE_ROOT") {
        this.businessList.forEach((element) => {
          if (element.ID_Business == this.form.userbusiness) {
            this.form.businesstype = element.BusinessType;
          }
        });
      } else {
        this.form.businesstype = JSON.parse(localStorage.getItem("user")).businesstype;
      }
      formData.append("user", JSON.stringify(this.form));
      formData.append("file", this.userimage);
      formData.append("sello", this.sello);
      /*  formData.append("sello", this.sellofname) */
      this.$API.users
        .addUser(formData)
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Usuario creado",
            "El usuario se ha creado correctamente",
            "usereload"
          );
          // this.$router.push({ name: 'UserList' })
          this.form = { status: true };
          this.form.webAccess = true;


          this.url = null;
        })
        .catch(() => {
          this.loading = false;
          this.showAlert("danger", "Error", "El usuario no se ha creado correctamente");
        });
    },
    updateUser() {
      if (this.checkImageSize()) return;

      if (!this.valid) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe llenar todos los campos requeridos"
        );
        return;
      }
      this.loading = true;
      let formData = new FormData();
      if (this.validSeller == false) {
        this.showAlert("danger", "Error", "El tamaño del archivo debe ser menor a 2 Mb");
        return;
      }
      this.form.userChangingPasswordRole = this.type_user;

      // console.log( 'USER TO SEND ' );
      // console.log( this.form )

      formData.append("user", JSON.stringify(this.form));
      formData.append("file", this.userimage);
      formData.append("sello", this.sello);
      for (const [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      this.$API.users
        .updateUser(this.form.iD_User, formData)
        .then((resp) => {
          this.loading = false;
          // this.$emit('reloadData');
          this.showAlert(
            "success",
            "Usuario  Actualizado",
            "Datos de usuario agregados correctamente",
            "usereload"
          );
          console.log(resp);
          //changue the propertie of the user
          let CurrentUser = JSON.parse(localStorage.getItem("user")).id;
          let user = this.form.iD_User;
          //resp.user.SelloLocalPath
          if (CurrentUser == user) {
            let user = JSON.parse(localStorage.getItem("user"));
            user.seal = resp.user.SelloLocalPath;
            localStorage.setItem("user", JSON.stringify(user));
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.showAlert(
            "danger",
            "Error",
            "El usuario no se ha podido actualizar de manera correcta"
          );
        });
    },
    closeDialog(payload) {
      this.deleteDialog = false;
      if (payload.isDeleted) {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("reloadData", "users");
      }
    },
    selectedSeller({ selectedRowsData }) {
      this.selectedItems = {
        ID_Seller: selectedRowsData[0].ID_Seller,
        ID_User: this.user.ID_User,
      };
      console.log(this.selectedItems);
    },
    selectSeller() {
      this.loading = true;
      this.selectSellerDialog = false;
      this.$API.users
        .updateUserSeller(this.selectedItems)
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Vendedor asignado",
            "El vendedor ha sido asignado correctamente"
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert("danger", "Error", "El vendedor no se ha podido asignar");
        });
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event?.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.background-card {
  background-color: white !important;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btn-add-seller {
  width: 80% !important;
}
</style>

<style>
/*.fixing .menuable__content__active .v-list{
    max-height: 90px !important;
}*/
</style>
