export const readBlobAsJson = ( responseData ) => {
    var b = new Blob([responseData]),
    fr = new FileReader();

    return new Promise( ( resolve, reject ) => {
        fr.onerror = () => {
            fr.abort();
            reject("Incorrect format");
        };

        fr.onload = function() {
            try {
                resolve(JSON.parse(this.result))
            } catch (error) {
                resolve( {} );
            }
        };
    
        fr.readAsText(b);

    } );
}