<template>
  <div>
    <div :class="breakline?`orderedBeside ml-4 mr-4`:`orderedBesidenobreaklined ml-4 mr-4`">
      <v-row :class="` d-flex flex-wrap`" style="justify-content: flex-end !important;">
        <v-col :cols="twoSlots ? 2 : 0" md="5" sm="4" lg="4" xl="4">
          <slot name="addButton"> </slot>
        </v-col>
        <v-col md="4" lg="5" xl="4"> </v-col>
        <div v-if="breakline" class="break-line"></div>
        <v-col class="col-7-input" style="display: flex; flex-wrap: nowrap;" :cols="twoSlots ? 10 : 12"  md="8">
          <v-text-field
            ref="filterInput"
            outlined
            dense
            label="Buscar..."
            v-model="search.filter"
            @input="handleEmptyFilter"
            @keyup.enter="filterHandler"
          ></v-text-field>
          <v-btn ref="filterButton" class="btn-add" style="margin-left: 2%;" @click="filterHandler"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div>
      <div :style="!isAutoScrolled && 'overflow-x:scroll;'">
        <slot> </slot>
      </div>
      <div v-if="!hideLimitLabel" class="d-flex justify-center mt-1 mb-2">
        <p>En la prevista se muestras los últimos 100 registros agregados</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralFilter",

  props: {
    filterEndpoint: {
      type: Function,
      default: null,
    },
    search: {
      type: Object,
      default: null,
    },
    hideLimitLabel: {
      type: Boolean,
      default: false,
    },
    isAutoScrolled: {
      type: Boolean,
      default: false,
    },
    twoSlots: {
      type: Boolean,
      default: false,
    },
    breakline: {
      type:Boolean,
      default: false
    }
  },

  data() {
    return {
      filter: null,
      twoSlotss: this.twoSlots
    };
  },
  watch: {
    filter(newVal) {
      this.$emit("input", newVal);
    },
    
  },
  methods: {
    focusInput() {
    this.$refs.filterInput.focus();  // Asegúrate de que filterInput es el ref del v-text-field
  },
    handleEmptyFilter() {
      if (this.search.filter.length === 0) {
        this.$emit("emptyFilter");
      }
    },
    filterHandler() {
      console.log("search", this.search);
      if (this.search.filter?.length != 0 && this.search.filter) {
        this.filterEndpoint(this.search)
          .then((response) => {
            console.log("filtrado", response);
            this.$emit("filtered", response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$emit("emptyFilter");
      }
    },
  },
};
</script>

<style scoped>
.orderedBeside {
  @media screen and (min-width: 1068px) {padding-top: 44px;}
  
}

.orderedBesidenobreaklined {
  padding-top: 44px !important;
}

.break-line {
  display: none; /* Oculta por defecto */
}

@media (max-width: 1217px) and (min-width: 1069px) { /* Activa el salto de línea solo bajo 1200px */
  .break-line {
    display: block;
    width: 100%;
    height: 60px; /* Mantiene el div sin altura */
  }
}
</style>

