<template>
    <v-card class="white border-boxGlobal" style="padding: 1rem; min-height: 60dvh;">
        <block v-if="loading" />

        <v-row class="ml-1 mr-1">
            <v-col class="tabsNH" cols="12">
                <v-col cols="12" style="display: flex; justify-content: space-between; min-height: 50px">
                    <div>
                        <v-btn small class="mx-2 btndelete" v-if="selectedRequest" @click.prevent="handleDeleteRequest" style="align-self: flex-start;">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                    <div>
                        <v-btn small class="mx-2 btn-add" @click.prevent="showDataToAdjust = true">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                        <v-btn small class="mx-2 btnclean" v-if="selectedRequest" @click="handleReset">
                            <v-icon>mdi-broom</v-icon>
                        </v-btn>
                        <v-btn small class="mx-2 btnsave" v-if="selectedRequest" @click.prevent="saveAdjustmentRequest" :disabled="selectedRequest &&  selectedRequest.Status == 2">
                            <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-col>
        </v-row>

        <v-card-title class="mt-2 mb-2"> Verificación de solicitudes de ajuste de inventario </v-card-title>

        <v-row class="mx-1 mt-2">
			<form style="display: flex; flex-direction: row; align-items: center; gap: 40px;">
				<div class="book-radio">
					<label for="allItems">
						<input
							type="radio" name="showItems" id="allItems"
							v-model="itemsType" value="0"
							class="custom-radio"
						/>
						<span class="custom-radio"></span>
						Mostrar todos
					</label>
				</div>
				<div class="book-radio">
					<label for="notAdjustItems">
						<input
							type="radio" name="showItems" id="notAdjustItems"
							v-model="itemsType" value="1"
							class="custom-radio"
						/>
						<span class="custom-radio"></span>
						Mostrar solo sin ajustes
					</label>
				</div>
			</form>
		</v-row>

        <v-row style="padding: 0 2rem; justify-content: flex-end;">
            <v-col cols="12" md="4">
                <date-picker label="Fecha verificación de ajuste" @dateSelected="handleAdjustmentDate" v-model="adjustmentDate"
                    :value="adjustmentDate"/> 
            </v-col>
        </v-row>

        <v-row v-if="selectedRequest" style="padding: 0 1rem;">
            <v-col cols="12" md="4">
                <p>Solicitud de ajuste ID: <strong>{{ selectedRequest.ID_Request }}</strong></p>
            </v-col>
            <v-col cols="12" md="4">
                <p>Estado del ajuste: <strong>{{ selectedRequest.Status ? statusParams[selectedRequest.Status] : null }}</strong></p>
            </v-col>
        </v-row>
        <v-card-actions class="mt-3" v-if="adjustmentRequestDetail.length">
            <v-btn v-if="selectAll" @click.prevent="handleSelectAll(false)" class="btn-add" :disabled="selectedRequest && selectedRequest.Status == 2">Deseleccionar todos</v-btn>
            <v-btn v-else @click.prevent="handleSelectAll(true)" class="btn-add" :disabled="selectedRequest && selectedRequest.Status == 2">Seleccionar todos</v-btn>
        </v-card-actions>

        <DxDataGrid 
            class="isScrolledByGeneralFilter" 
            :data-source="adjustmentRequestDetail" 
            :show-borders="true"
            @exporting="onExporting"  
            :scrolling="{ useNative: true }"
            :column-auto-width="true"
            key-expr="ID"
        >
            <DxExport :enabled="true" />
            <DxEditing :allow-updating="true" mode="cell" />
            <DxColumn data-field="ID_Item" caption="ID Producto" :allow-editing="false" />
            <DxColumn data-field="PrincipalBarCode" caption="Código Alterno Principal" :allow-editing="false" />
            <DxColumn data-field="ItemName" caption="Nombre Producto" :allow-editing="false" />
            <DxColumn data-field="ID_ItemBatch" caption="Lote" :allow-editing="false" />
            <DxColumn data-field="Warehouse" caption="Almacén" :allow-editing="false" />
            <DxColumn data-field="Family" caption="Familia" :allow-editing="false" />
            <DxColumn data-field="Category" caption="Categoria" :allow-editing="false" />
            <DxColumn data-field="SubCategory" caption="Subcategoria" :allow-editing="false" />
            <DxColumn data-field="OldLocation.location" caption="Ubicación Anterior" :allow-editing="false" />
            <DxColumn data-field="NewLocation.location" caption="Nueva Ubicación" :allow-editing="false" />
            <DxColumn data-field="TotalStock" :width="100" caption="Existencias" :allow-editing="false" />
            <DxColumn data-field="StockAdjustment" :width="100" caption="Existencia Fisica" :allow-editing="true" data-type="number" />
            <DxColumn v-if="selectedRequest && selectedRequest.Status !== 2" cell-template="checkItem" caption="Ajustar" :width="80"/>
            <template #checkItem="{ data }">
                <v-checkbox v-if="data.data.Status == 0" v-model="data.data.checked" @change="data.data.checked = $event" style="height: 20px; width: 20px; padding: 0; margin-inline: auto;"></v-checkbox>
            </template>
            <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
            <DxPaging :page-size="10" />
        </DxDataGrid>

        <!-- <v-card-actions class="mt-5 mb-5">
            <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                        <v-btn class="btn-add" @click.prevent="saveAdjustmentRequest" :disabled="!adjustmentDate">Guardar solicitud</v-btn>
                    </v-col>
            </v-row>
        </v-card-actions> -->

        <v-dialog v-model="showDataToAdjust" max-width="80%" scrollable >
            <v-card class="white border-boxGlobal" style="padding: 1rem; overflow: auto;">
                <GeneralFilter 
                    :filterEndpoint="$API.inventoryReports.getFilteredAdjustmentRequest"
                    :search="search" 
                    @emptyFilter="getAdjustmentRequest" 
                    @filtered="filterHandler"
                >
                    <DxDataGrid 
                        class="isScrolledByGeneralFilter" 
                        :data-source="inventoryAdjustmentRequests" 
                        :show-borders="true"
                        :scrolling="{ useNative: true }"
                        :column-auto-width="true"
                        key-expr="ID"
                    >
                        <DxExport :enabled="false" />
                        <DxColumn cell-template="selectToCheck" caption="" :width="80"/>
                        <DxColumn data-field="ID_Request" caption="ID Solicitud" />
                        <DxColumn data-field="RequestDate" caption="Fecha de solicitud" />
                        <DxColumn data-field="AdjustmentDate" caption="Fecha de verificación" />
                        <DxColumn cell-template="StatusRequest" caption="Estado" />
                        <DxColumn data-field="ID_User_Request" caption="Usuario solicitante" />
                        <DxColumn data-field="ID_User_Adjust" caption="Usuario verificador" />
                        <template #selectToCheck="{ data }">
                            <a href="#" @click.prevent.stop="handleSelectRequest(data.data)">
                                <v-icon>mdi-eye</v-icon>
                            </a>
                        </template>
                        <template #StatusRequest="{ data }">
                            <p>{{ statusParams[data.data.Status] }}</p>
                        </template>
                        <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                        <DxPaging :page-size="10" />
                    </DxDataGrid>
                </GeneralFilter>
            </v-card>
        </v-dialog>

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-card>
</template>

<script>
import { DxDataGrid, DxColumn, DxExport, DxPager, DxPaging, DxEditing } from "devextreme-vue/data-grid";
import { printToExcel } from "@/helpers/printToexcel";
//import VersatileFilter from "@/components/VersatileFilter";
import GeneralFilter from  "@/components/GeneralFilter";
import Block from "@/components/Block";
import DatePicker from "@/components/DatePicker";
import Alerts from "@/components/Alerts";

export default {
    name: "InventoryAdjustmentCheck",
    components: {
        Block,
        DxDataGrid,
        DxColumn,
        DxPager,
        DxExport,
        DxPaging,
        DxEditing,
        DatePicker,
        Alerts,
        GeneralFilter,
    },
    props: ["win", "datafilter"],
    data() {
        return {
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            loading: false,
            panel: 0,
            inventoryAdjustmentRequests: [],
            adjustmentRequestDetail: [],
            itemsType: '0',
            start: null,
            end: null,
            summary: {
                totalUnitSales: 0,
                totalCostSales: 0,
            },
            search: {
                status: 0
            },
            showFilter: false,
            filterParams: {
                ID_Item: null,
                ItemName: null,
                ItemLote: null,
                providersNames: null,
                WarehouseName: null,
                add: null,
            },
            Reloadfilter: 0,
            columsConfig: [
                {
                    name: "ID_Warehouse",
                    title: "Almacenes",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "family",
                    title: "Familia",
                    value: "",
                    type: "select",
                    options: [],
                },
                {
                    name: "category",
                    title: "Categoría",
                    value: "",
                    parent: 'family',
                    type: "select",
                    options: [],
                },
                {
                    name: "subcategory",
                    title: "Subcategoría",
                    value: "",
                    parent: 'category',
                    type: "select",
                    options: [],
                },
                {
                    name: "Lote",
                    title: "Lote",
                    value: "",
                    type: "select",
                    options: [],
                },
            ],
            statusParams: ['PENDIENTE' , 'ENVIADO' , 'PROCESADO' , 'PROCESADO PARCIAL'],
            cacheData: [],
            selectAll: false,
            selectedRequest: null,
            selectedItem: null,
            adjustmentDate: null,
            adjustmentForm: {},
            dataToAdjust: [],
            showComments: false,
            showDataToAdjust: false,
            warehouses: [],
            entryKey: null,
           
        };
    },
    computed: {
        selectedItemAdjWarehouseName() {
            if ( this.adjustmentForm.warehouse && !this.selectedItem.WarehouseName ) {
                return this.datafilter.Warehouses.find( current => {
                    const { value: ID_Warehouse } = current;
                    return ID_Warehouse === this.adjustmentForm.warehouse;
                } ).name;
            } else {
                return this.selectedItem.WarehouseName
            } 
        },
        totalStockItemAdjToShow() {
            if (this.selectedItem.TotalStock && this.adjustmentForm.quantity && !this.adjustmentForm.add) {
                return this.selectedItem.TotalStock - this.adjustmentForm.quantity;
            } else if (this.selectedItem.TotalStock && this.adjustmentForm.quantity && this.adjustmentForm.add) {
                return this.selectedItem.TotalStock + +this.adjustmentForm.quantity;
            } else if (this.selectedItem.TotalStock) {
                return this.selectedItem.TotalStock;
            }
            else if((this.selectedItem.TotalStock == null || this.selectedItem.TotalStock == undefined || this.selectedItem.TotalStock == 0 ) && this.adjustmentForm.quantity  ) 
            {
                return this.adjustmentForm.add ? this.adjustmentForm.quantity : -this.adjustmentForm.quantity;
            } 
            return 0;
        },
        getSetLoteAdjust: {
            get () {        
                return this.selectedItem.Lote
            },
            set (val) {
                // alert(val)
                if (val=="") {
                    this.adjustmentForm.lote =  this.selectedItem.Lote
                } else {
                    this.adjustmentForm.lote = val
                }   
            }
        },
        getSetAverageCostAdjust: {
            get () {
                return this.selectedItem.AverageCost
            },
            set (val) {
                this.adjustmentForm.price = val
            }
        },
        getSetWarehouseAdjust: {
            get () {        
                return this.selectedItem.Warehouse
            },
            set (val) {
                // alert(val)
                if (val=="") {
                    this.adjustmentForm.warehouse = this.selectedItem.Lote
                } else {
                    this.adjustmentForm.warehouse = val
                }
            
            }
        },
        stockItemAdjToShow() {
            return this.selectedItem.TotalStock ? this.selectedItem.TotalStock : 0;
        },
    },
    watch: {
		itemsType() {
			this.loading = true;
            if(this.selectedRequest) {
                this.handleSelectRequest(this.selectedRequest);
            }
			this.loading = false;
		},
	},
    methods: {
        verifyR() {
            if (!this.selectedItem) {
                this.showAlert("warning", "Ajuste de inventario", "Seleccione un producto");
                return;
            }
            this.showDataToAdjust = true;
        },
        closeAlert() {
            this.alert.show = false;

            if (this.alert.options == "reload") {
                this.getAdjustmentRequest();
            }
            else if (this.alert.options == "reloadData") {
                this.reloadData();
            }
        },
        acceptAlert() {
            this.alert.show = false;
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        reloadData(){
            this.getAdjustmentRequest();
            this.adjustmentRequestDetail = [];
            this.selectedRequest = null;
        },
        handleSelectRequest(data) {
            this.selectedRequest = data;
            
            for (const item of this.selectedRequest.AdjustmentRequestDetail) {
                if(item.TotalStock == item.StockAdjustment) {
                    item.isEqual = true;
                }
            }
            this.adjustmentRequestDetail = this.selectedRequest.AdjustmentRequestDetail.filter(item => !item.isEqual && (this.itemsType == '1' ? item.Status == 0 : true));
            this.showDataToAdjust = false;        
        },
        handleSelectAll(value) {
            this.selectAll = value;

            if(this.selectAll === true) {
                for (const item of this.adjustmentRequestDetail) {
                    item.checked = true;
                }
                return;
            }
            else {
                for (const item of this.adjustmentRequestDetail) {
                    item.checked = false;
                }
                return;
            }
        },
        handleSelectorItem(e) {
            this.entryKey = e;
            const item = this.inventoryAdjustmentRequests.find((el) => el.ID_Item == e);
            if(item) {
                item.checked = true;
                item.stockAdjustment = '';
                this.dataToAdjust.push(item);
            }
        },
        handleDeleteAdjustmentData(id) {
            this.dataToAdjust = this.dataToAdjust.filter(item => item.ID_Item !== id);
        },
        async saveAdjustmentRequest() {
            this.loading = true;

            const adjustmentDetail = [];
            
            for (const item of this.adjustmentRequestDetail) {
                if(item.checked) {
                    item.Status = 1;
                    adjustmentDetail.push(item);
                }
            }

            if(!adjustmentDetail.length) {
                this.loading = false;
                return this.showAlert("warning", "Advertencia", "No ha seleccionado items para confirmar los ajustes.");
            }

            let status = 0;

            if(this.adjustmentRequestDetail.length == adjustmentDetail.length) status = 2;
            else if([...this.adjustmentRequestDetail].filter(item => item.Status == 1).length == this.adjustmentRequestDetail.length ) status = 2;
            else status = 3;
            
            const adjustment = {
                ID_Request: this.selectedRequest.ID_Request,
                adjustmentRequestDetail: adjustmentDetail,
                adjustmentDate: this.adjustmentDate,
                user: JSON.parse(localStorage.getItem('user')).id,
                status: status
            }
            
            await this.$API.inventoryReports.confirmInventoryAdjustment(adjustment)
                .then((response) => {
                    this.loading = false;
                    this.showAlert("success", "Solicitud de ajuste de inventario", response.message, "reloadData");
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.showAlert("warning", "Solicitud de ajuste de inventario", "Ocurrio un error al enviar la solicitud.");
                });
        },
        updateCache(data) {
            this.cacheData = data;
            
        },
        selectionChanged(e) {
            var selectedRows = e.selectedRowsData;
            if (selectedRows.length > 0) {
                this.selectedItem = selectedRows[0];
            }
        },
        getAdjustmentRequest() {
            this.loading = true;
            this.$API.inventoryReports.getAllAdjustmentRequest({ status: 0 })
                .then((response) => {
                    this.loading = false;
                    this.inventoryAdjustmentRequests = response.detail;
                    this.selectedRequest = null;
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                });
        },
        onExporting(e) {
            printToExcel(e, "Plantilla de solicitud de ajustes (Informe Físico)");
        },
        filterHandler(response) {
            this.inventoryAdjustmentRequests = response.detail;
        },
        filterListHandler(response) {
            this.inventoryDataList = response;
        },
        cleanFilter() {
            this.filterParams = {
                ID_Item: null,
                ItemName: null,
                ItemLote: null,
                providersNames: null,
                WarehouseName: null,
            };
            this.getAdjustmentRequest();
        },
        getFilterEarningDetail() {
            this.loading = true;
            this.$API.inventoryReports
                .getInventoryFilterReport(this.filterParams)
                .then((response) => {
                    this.loading = false;
                    this.inventoryAdjustmentRequests = response;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        handleAdjustmentDate(date) {
            this.adjustmentDate = date;
        },
        handleDeleteRequest() {
            if(this.selectedRequest.Status == 2 || this.selectedRequest.Status == 3) {
                return this.showAlert("warning", "Solicitud de ajuste", "No se puede eliminar la solicitud debido a que ya existen ajustes confirmados.");
            }
            
            this.loading = true;
            this.$API.inventoryReports.deleteRequestInventoryAdjustment({ ID_Request: this.selectedRequest.ID_Request , ID: this.selectedRequest.ID })
                .then((response) => {
                    this.loading = false;
                    this.showAlert("success", "Solicitud de ajuste", response.message, "reloadData");
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.showAlert("warning", "Solicitud de ajuste", "Ocurrio un error al eliminar la solicitud.");
                });
        },
        assignParams() {
            if (this.datafilter) {
                this.columsConfig[0].options = this.datafilter.Warehouses;
                this.columsConfig[1].options = this.datafilter.Families;
                this.columsConfig[2].options = this.datafilter.Categories;
                this.columsConfig[3].options = this.datafilter.SubCategories;
                this.columsConfig[4].options = this.datafilter.batches;
                this.Reloadfilter += 1;
            }
        },
        handleReset() {
            this.getAdjustmentRequest();
            this.adjustmentRequestDetail = [];
        },
       
    },
    mounted() {
        this.assignParams();
        this.getAdjustmentRequest();
      
    },
};
</script>

<style scoped>
.btnblack {
    background-color: rgba(60, 60, 60, 1) !important;
    color: white !important;
}

.close-btn {
    color: white !important;
    position: relative !important;
    float: right !important;
}

.border {
    border-radius: 15px !important;
}

.tabsNH {
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    margin-top: 1rem;
    border-radius: 10px;
}
.book-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: black;
    margin: 0 0 20px 12px;
}
.book-radio label {
    cursor: pointer;
}
.book-radio input[type="radio"] {
        display: none;
}
.book-radio .custom-radio {
    width: 14px;
    height: 14px;
    border: 2px solid #777;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
    align-self: center;
}
.book-radio .custom-radio::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: background-color 0.3s;
}
.book-radio input[type="radio"]:checked + .custom-radio::before {
    background-color: #3498DB;
}
.book-radio label {
    display: flex;
    gap: 8px;
    align-items: center;
}
</style>
