import axios from "./axios";

export default {
    /**
     * Get all roles
     * @api {Get} roles/all
     */
    getAllRoles() {
        const options = {
            method: 'GET',
            url: 'roles/all',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    setAllRoles(data) {
        const options = {
            method: 'POST',
            url: 'roles/updateAll',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    assignRole(data) {
        const options = {
            method: 'PUT',
            url: 'roles',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    async assignFromRolesGroupName( data ) {
        try {
            const options = {
                method: 'PUT',
                url: 'roles/assignrolesgroupname',
                headers: { 'content-type': 'application/json' },
                data
            }
            
            const { data: responseResult } = await axios( options );

            console.log( 'responseResult ', responseResult );
            if( !responseResult.success ) {
                throw responseResult;
            }

            return responseResult;
        } catch (error) {
            console.log( error )
            throw error;
        }
    },
    getUserRole(id) {
        const options = {
            method: 'GET',
            url: 'roles/' + id,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getUserRole2(id, branch) {
        const options = {
            method: 'GET',
            url: 'roles/getuserroles2/' + id + "/" + branch,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    saveRole(data) {
        const options = {
            method: 'POST',
            url: '/roles/',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getRolesGroupName(data) {
        const options = {
          method: 'POST',
          url: 'roles/rolesgroupname',
          headers: { 'content-type': 'application/json' },
          data: data
        };
      
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message);
            });
        });
    },
    
    getGroups() {
        const options = {
          method: 'GET',
          url: 'roles/rolesgetgroups',
          headers: { 'content-type': 'application/json' },
        };
      
        return new Promise((resolve, reject) => {
          axios(options)
            .then(response => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error.message);
            });
        });
    } 
}
