import { readBlobAsJson } from "../helpers/responsereceiver";
import axios from "./axios";

export default {
    /**
     * Add Patient
     * @api {POST} /business
     */
    async exportAccountCatalog() {
        const options = {
            method: 'GET',
            url: '/branchaccountcatalog/exportaccounts',
            headers: {'content-type': 'application/json'},
            responseType: 'blob',
        };

        const response = await axios( options );
        
        const jsonResponse = await readBlobAsJson( response.data )
        
        const {
            status,
            message
        } = jsonResponse;

        if( status === 204 ) {
            throw new Error( message );
        }

        response.message = 'La exportación de cuentas se ha realizado correctamente';
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'catalogo_cuentas.xlsx');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        return response;
    },

    importAccountCatalog(file) {
        if(!file) throw new Error({message: 'Debe seleccionar un archivo'});

        const formData = new FormData();
        formData.append('excelFile', file);

        const options = {
            method: 'POST',
            url: '/branchaccountcatalog/importaccounts',
            headers: {'content-type': 'multipart/form-data'},
            data: formData
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    console.log('Error al subir el archivo Excel', error);
                    reject(error);
                })
        });
    },

    getBranchAccountCatalog() {
        const options = {
            method: 'GET',
            url: '/branchaccountcatalog',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getActiveBranchAccountCatalog() {
        const options = {
            method: 'GET',
            url: '/branchaccountcatalog/getActive',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAssignableAccounts() {
        const options = {
            method: 'GET',
            url: '/branchaccountcatalog/getassignableaccounts',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getActiveFilterBranchAccountCatalog(data) {
        const options = {
            method: 'GET',
            url: '/branchaccountcatalog/getActivefilter',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAcounteringType(data){
        const options = {
            method: 'GET',
            url: '/accountingentrytype',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        console.log(response);
                        reject(response.data);
                    }

                })
                .catch(error => {
                    reject(error)
                })
        })        
    },
    getLastCode(data) {
        const options = {
            method: 'POST',
            url: '/branchaccountcatalog/lastcode',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data.lastCode);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createBranchAccount(data) {
        const options = {
            method: 'POST',
            url: '/branchaccountcatalog',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    if(response.data.success){
                        resolve(response.data);
                    } else {
                        console.log(response);
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    updateBranchAccount(data) {
        const options = {
            method: 'PUT',
            url: '/branchaccountcatalog',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    if(response.data.success){
                        resolve(response.data);
                    } else {
                        console.log(response);
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteBranchAccount(data) {
        const options = {
            method: 'DELETE',
            url: '/branchaccountcatalog',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    if(response.data.success){
                        resolve(response.data);
                    } else if(response.data.code === 'ORPHRECORDS') {
                        const { code, error } = response.data;
                        resolve( { code, error } );
                    } else { 
                        console.log(response);
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    assignAccountsProducts(data) {
        const options = {
            method: 'PUT',
            url: '/branchaccountcatalog/assign/products',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    if(response.data.success){
                        resolve(response.data);
                    } else {
                        console.log(response);
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    assignAccountsServices(data) {
        const options = {
            method: 'PUT',
            url: '/branchaccountcatalog/assign/services',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    if(response.data.success){
                        resolve(response.data);
                    } else {
                        console.log(response);
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    automaticAccountingEntries(data) {
        const options = {
            method: 'POST',
            url: '/branchaccountcatalog/automaticaccountingentries',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    if(response.data.success){
                        resolve(response.data);
                    } else {
                        console.log(response);
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    assignAccountsProviders(data) {
        const options = {
            method: 'PUT',
            url: '/branchaccountcatalog/assign/providers',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    console.log(response);
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    if(response.data.success){
                        resolve(response.data);
                    } else {
                        console.log(response);
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
}