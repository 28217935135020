<template>
    <v-expansion-panel>
        <template>
            <v-expansion-panel-header class="mt-2">
               Balances
                <template v-slot:actions>
                    <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                        <v-icon color="primary">mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                        <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        Balance General
                        <v-icon>mdi-chart-bar</v-icon>
                    </v-tab>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-2">
                        Balance de Comprobación
                        <v-icon>mdi-chart-bar</v-icon>
                    </v-tab>
                    <v-tab href="#tab-4">
                        Anexo Balance de Comprobación
                        <v-icon>mdi-chart-bar</v-icon>
                    </v-tab>
                    <v-tab href="#tab-5">
                        Estado de resultados
                        <v-icon>mdi-chart-bar</v-icon>
                    </v-tab>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-3">
                        Balance de Detalle por Cuenta
                        <v-icon>mdi-chart-bar</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-1">
                        <v-card flat class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <GeneralBalance :win="win" :key="key" />                   
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card flat class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <SheetCheckBalance :win="win" :key="key" />                   
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-4">
                        <v-card flat class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <SheetCheckBalanceAnnex :win="win" :key="key" />                   
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-5">
                        <v-card flat class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <ResultsStatementsReport :win="win" :key="key" />                   
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-3">
                        <v-card flat class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                <BalanceDetailByAccount :win="win" :key="key" />                   
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-expansion-panel-content>
        </template>
        
    </v-expansion-panel>
</template>

<script>
    import GeneralBalance from '../partial/GeneralBalance.vue';
	import SheetCheckBalance from '../partial/SheetCheckBalance.vue';
    import BalanceDetailByAccount from '../partial/BalanceDetailByAccount.vue';
    import SheetCheckBalanceAnnex from '../partial/SheetCheckBalanceAnnex.vue';
    import ResultsStatementsReport from '../partial/ResultsStatementsReport.vue';

    export default {
        name: "AccountingBalance",
        components: {
            GeneralBalance,
			SheetCheckBalance,
            BalanceDetailByAccount,
            SheetCheckBalanceAnnex,
            ResultsStatementsReport
        },
        props: ['win'],
        data() {
            return {
                panel: 0,
                tab: null,
                key: 0,
            }
        },
        methods: {
            reload() {
                this.key++;
                setTimeout(() => {
                    window.scrollTo(0, this.$store.getters.getScrollTop)
                },300);
            },
        },

    }
</script>

<style scoped>

</style>