<template>
  <v-expansion-panel v-model="panel">
    <v-expansion-panel-header class="mt-2">
      LISTA DE PRECIOS
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="reload()" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-form v-model="valid">
        <v-card>
          <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab href="#tab-1">
              LISTA DE PRECIOS
              <v-icon>mdi-account-search</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <block v-if="loading"></block>
              <v-card flat class="pa-1">
                <v-dialog v-model="showPriceList" width="60%">
                  <v-card>
                    <v-btn elevation="0" color="red" class="close-btn" @click.prevent="showPriceList = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-card-title style="
                          margin-top: 0.3rem;
                          margin-bottom: 0.3rem;
                          display: flex;
                          justify-content: space-between;
                        ">
                      <h2 class="mt-3">Lista de Precios</h2>
                    </v-card-title>
                    <v-card-text>
                      <!-- Table 1 Start - PriceList -->
                      <GeneralFilter entity="lista" :filterEndpoint="$API.priceList.priceListFiltered" :search="search"
                        @emptyFilter="getAllPriceList" @filtered="filterHandlerPriceList">
                        <DxDataGrid  :data-source="priceList" key-expr="id"
                          :show-row-lines="showRowLines" @exporting="onExportingLP" :show-borders="showBorders"
                          :row-alternation-enabled="rowAlternationEnabled"
                          :scrolling="{ useNative: true }"    
                          :column-auto-width="true"
                        >  
                          <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 15]" />
                          <DxPaging :page-size="10" />
                          <DxExport :enabled="true" />
                          <DxColumn :width="columnWidth" cell-template="show-template" caption="">
                          </DxColumn>
                          <DxColumn :width="columnWidth" data-field="date" caption="Fecha de Creación"></DxColumn>
                          <DxColumn :width="columnWidth"  data-field="id" caption="Nombre de la Lista de Precios"></DxColumn>
                          <DxColumn :width="columnWidth"  v-if="rolesStatus.findIndex(
                            (role) => role.RoleName == 'admin'
                          ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.RoleName == 'root'
                            ) > -1
                            " cell-template="actives-show" caption="Principal"></DxColumn>
                          <template #show-template="{ data }">
                            <a href="#" class="mx-1" @click.prevent.stop="
                              showProductOld(data);
                            showPriceList = false;
                            ">
                              <v-icon color="primary">mdi-eye</v-icon>
                            </a>
                          </template>
                          <template #actives-show="{ data }">
                            <div class="d-flex justify-center align-center checkboxSize">
                              <v-checkbox v-model="data.data.Active" @change="handleChanged(data.data)"></v-checkbox>
                            </div>
                          </template>
                        </DxDataGrid>
                      </GeneralFilter>
                      <!-- Table 1 End - PriceList -->
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <div>
                  <v-col class="box">
                    <v-row class="ml-2 mr-2" style="margin-top: 0.3rem">
                      <v-col cols="12" class="tabsNh">
                        <v-col v-if="update" cols="12" md="12" sm="12" style="display: flex; justify-content: flex-end">
                          <v-btn v-if="form.pricelistname != 'Precios por defecto'" small class="mr-1 btndelete" @click.prevent="showWarningBeforeDelete()">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <v-btn small class="mr-1 btn-add" @click.prevent="showPriceList = !showPriceList">
                            <v-icon> mdi-magnify </v-icon>
                          </v-btn>
                          <v-btn small class="btnclean mr-1" @click.prevent="cleanData">
                            <v-icon>mdi-broom</v-icon>
                          </v-btn>
                          <v-btn small class="mr-1 btnsave" v-if="rolesStatus.findIndex(
                            (role) => role.RoleName == 'admin'
                          ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.RoleName == 'root'
                            ) > -1 ||
                            rolesStatus.findIndex(
                              (role) => role.ID_Role == 44
                            ) > -1
                            " @click.prevent.stop="addNewPriceList">
                            <v-icon>mdi-content-save</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" v-else style="
                              display: flex;
                              justify-content: space-between;
                            ">
                          <div></div>
                          <div>
                            <v-btn small class="mr-1 btn-add" @click.prevent="showPriceList = !showPriceList">
                              <v-icon> mdi-magnify </v-icon>
                            </v-btn>

                            <v-btn small class="btnclean mr-1" @click.prevent="cleanData">
                              <v-icon>mdi-broom</v-icon>
                            </v-btn>

                            <v-btn v-if="rolesStatus.findIndex(
                              (role) => role.RoleName == 'admin'
                            ) > -1 ||
                              rolesStatus.findIndex(
                                (role) => role.RoleName == 'root'
                              ) > -1 ||
                              rolesStatus.findIndex(
                                (role) => role.ID_Role == 43
                              ) > -1
                              " small class="mr-1 btnsave" @click.prevent.stop="addNewPriceList">
                              <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-col>
                    </v-row>

                    <div class="d-flex flex-wrap align-self-center justify-space-between mb-4 pa-3">
                      <v-col cols="12" md="6" sm="12">
                        <v-text-field outlined dense v-model="form.pricelistname" label="Nombre de Lista de Precios"
                          class="mx-5" :rules="[
                            (valid) =>
                              !!valid ||
                              'El nombre de la lista de precio es requerido',
                          ]" required clearable></v-text-field>
                      </v-col>
                      <v-col v-if="productsToShow.length >= 1" cols="12" md="6" sm="12">
                        <v-btn @click.prevent="
                          showRelateClientPrice = !showRelateClientPrice
                          " class="btn-add mx-5"><v-icon class="mr-3">mdi-account-multiple-plus</v-icon>Relacionar
                          lista de precio a clientes</v-btn>
                      </v-col>
                     
                      <!-- Table 2 Start - ProductsToShow -->
                      <v-col cols="12">
                        <GeneralFilter entity="producto" :filterEndpoint="filterProductsToShow" :search="search"
                          @emptyFilter="emptyFilter" @filtered="filterHandlerProductsToShow" v-show="exportData">
                          <!-- BTN add -->
                          <v-btn class="ml-4 btn-add btn-position" @click.prevent.stop="showPrices = !showPrices">
                            <v-icon class="mr-3">mdi-plus</v-icon>Agregar producto/servicio
                          </v-btn>
                          <!-- BTN add -->
                          <DxDataGrid class="isScrolledByGeneralFilter" :key="renderN" :data-source="productsToShowSource"
                            key-expr="ID_Item" :show-row-lines="showRowLines" @exporting="onExportingLP"
                            ref="priceListref" :show-borders="showBorders"
                            :row-alternation-enabled="rowAlternationEnabled">
                            <DxPaging :page-size="100" />
                            <DxColumn data-field="ID_Item" caption="ID de Producto/Servicio"></DxColumn>
                            <DxColumn data-field="BarCode" caption="Código Alterno"></DxColumn>
                            <DxColumn data-field="ItemNameToBill" caption="Nombre de Producto/Servicio"></DxColumn>
                            <DxColumn data-field="ItemOriginalPrice" caption="Precio Original"></DxColumn>
                            <DxColumn data-field="ItemPriceDiscountAmount" caption="Descuento $"></DxColumn>
                            <DxColumn data-field="ItemPriceDiscountPercent" caption="Descuento %"></DxColumn>
                            <DxColumn data-field="ItemNewPrice" caption="Precio Final">
                            </DxColumn>
                          </DxDataGrid>
                        </GeneralFilter>
                        <!-- Table 2 End - ProductsToShow -->
                        <!-- Table 3 Start - ProductsToShow -->
                        
                        <GeneralFilter entity="producto" :filterEndpoint="filterProductsToShow" :search="search"
                          @emptyFilter="emptyFilter" @filtered="filterHandlerProductsToShow">
                          <!-- BTN add -->
                          <v-row class="mb-10">
                            <v-col cols="12" md="3">
                              <v-btn class="ml-4 btn-add btn-position" @click.prevent.stop="showPrices = !showPrices">
                                <v-icon class="mr-3">mdi-plus</v-icon>Agregar producto/servicio
                              </v-btn>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-btn class="ml-4 btn-add btn-position" @click="onExportingP">
                                <v-icon class="mr-3">mdi-file-excel</v-icon>Exportar a excel
                              </v-btn>                              
                            </v-col>
                            <!-- <v-col cols="12" md="3">
                              <UploadXLSX :buttonCaption="'Cargar lista'" class="btn-position" />
                            </v-col> -->
                            <v-col cols="12" md="3">
                              <v-checkbox v-if="$store.getters['rolesUser/getArrayCustomRole']([254])" v-model="EditOriginalPrice" class="ml-4 btn-position" label="Editar precios originales"></v-checkbox>
                            </v-col>
                          </v-row>

                          <!-- BTN add -->
                          <DxDataGrid class="isScrolledByGeneralFilter" :data-source="productsToShowSource" key-expr="ID_Item"
                            :show-row-lines="showRowLines" :show-borders="showBorders"
                            :allow-column-reordering="true" :row-alternation-enabled="rowAlternationEnabled">
                            <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 15]" />
                            <DxPaging :page-size="10" />
                            <DxColumn cell-template="add-template" caption="" :width="50"></DxColumn>
                            <DxColumn data-field="ID_Item" caption="ID de Producto/Servicio" alignment="left"></DxColumn>
                            <DxColumn data-field="BarCode" caption="Código Alterno"></DxColumn>
                            <!-- <DxColumn data-field="ID_Item" caption="ID de Producto/Servicio" alignment="left" :group-index="0"></DxColumn> -->
                            <DxColumn data-field="ItemNameToBill" caption="Nombre de Producto/Servicio"></DxColumn>
                            <DxColumn cell-template="originalprice" caption="Precio Original"></DxColumn>
                            <DxColumn cell-template="initialr" caption="Rango Inicial" :width="130"></DxColumn>
                            <DxColumn cell-template="finalr" caption="Rango Final" :width="130"></DxColumn>
                            <DxColumn cell-template="product-discount" caption="Descuento $" :width="180"></DxColumn>
                            <DxColumn cell-template="product-discountpercent" caption="Descuento %" :width="180">
                            </DxColumn>
                            <DxColumn cell-template="newprice" caption="Precio Final">
                            </DxColumn>
                            <DxColumn v-if="rolesStatus.findIndex(
                              (role) => role.RoleName == 'admin'
                            ) > -1 ||
                              rolesStatus.findIndex(
                                (role) => role.RoleName == 'root'
                              ) > -1" :width="50" cell-template="delete-template" caption=""></DxColumn>
                            <template #newprice="{ data }">
                              <div>
                                {{ CurrentBranch.Currency }}{{ showDecimals(data.data.ItemNewPrice) }}
                              </div>
                            </template>
                            <template #originalprice="{ data }">
                              <div>
                                <template v-if="EditOriginalPrice">
                                  <div class="input-group">
                                    <input 
                                      v-model="data.data.ItemOriginalPrice"
                                      type="number" 
                                      min="0" 
                                      :max="data.data.ItemOriginalPrice" 
                                      @blur="changeDisO($event, data)"
                                      @change="changeDisO($event, data)"
                                      @click="selectAllText($event)" 
                                      class="form-control" 
                                    />
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">{{ CurrentBranch.Currency }}</span>
                                    </div>
                                  </div>
                                </template>
                                <template v-else>
                                  {{ CurrentBranch.Currency }}{{ showDecimals(data.data.ItemOriginalPrice) }}
                                </template>
                              </div>
                            </template>
                            <template #delete-template="{ data }">
                              <a href="#" class="mx-1" @click.prevent.stop="removeRow(data)">
                                <v-icon color="error">mdi-delete</v-icon>
                              </a>
                            </template>
                            <template #product-discount="{ data }">
                              <div class="input-group">
                                <input 
                                  v-model="data.data.ItemPriceDiscountAmount"
                                  type="number" 
                                  min="0" 
                                  :max="data.data.ItemOriginalPrice" 
                                  @blur="changeDis($event, data)"
                                  @change="changeDis($event, data)"
                                  @click="selectAllText($event)" 
                                  class="form-control" 
                                />
                                <div class="input-group-prepend">
                                  <span class="input-group-text">{{ CurrentBranch.Currency }}</span>
                                </div>
                              </div>
                            </template>
                            <template #product-discountpercent="{ data }">
                              <div class="input-group">
                                <input 
                                  v-model="data.data.ItemPriceDiscountPercent" 
                                  type="number" 
                                  :min="0" 
                                  :max="100"
                                  @blur="changeDisP($event, data)" 
                                  @change="changeDisP($event, data)" 
                                  @click="selectAllText($event)" 
                                  class="form-control"
                                />
                                <div class="input-group-prepend">
                                  <span class="input-group-text">%</span>
                                </div>
                              </div>
                            </template>
                            <template #initialr="{ data }">
                              <div class="input-group" v-if="data.data.DefaultRange">
                                Defecto
                              </div>
                              <div v-else>
                                <v-text-field v-model="data.data.InitialRange" class="form-control" dense hide-details
                                  type="number" @input="changeIntRange($event, data, 0)"
                                  oninput="if(this.value == '' || this.value == null) this.value = 0;"></v-text-field>
                              </div>
                            </template>
                            <template #finalr="{ data }">
                              <div class="input-group" v-if="data.data.DefaultRange">
                                Defecto
                              </div>
                              <div v-else>
                                <v-text-field v-model="data.data.FinalRange" class="form-control" dense hide-details
                                  type="number" @input="changeIntRange($event, data, 1)"
                                  oninput="if(this.value == '' || this.value == null) this.value = 0;"></v-text-field>
                              </div>
                            </template>
                            <template #add-template="{ data }">
                              <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                                <v-icon>mdi-content-copy</v-icon>
                              </a>
                            </template>
                          </DxDataGrid>
                        </GeneralFilter>
                      </v-col>
                      <!-- Table 3 End- ProductsToShow -->
                    </div>
                  </v-col>
                </div>

              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-form>
    </v-expansion-panel-content>

    <!-- Dialog service/products - start -->
    <v-dialog v-model="showPrices" width="80%" scrollable>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <h2 class="mt-3">Lista de Productos/Servicios</h2>
            </v-col>
            <v-col>
              <v-btn elevation="0" color="red" class="close-btn align-self-end mt-2" @click.prevent="showPrices = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="typeRadioProductService" class="mt-4 ml-8">
            <v-radio label="Productos" value="product" checked="true"></v-radio>
            <v-radio label="Servicios" value="service"></v-radio>
          </v-radio-group>
          
          <!-- Filter -->
          <v-dialog v-model="showFilter" width="30%">
            <v-card>
              <v-btn elevation="0" color="red" class="close-btn" @click.prevent="showFilter = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-card-title style="
              margin-top: 0.3rem;
              margin-bottom: 0.3rem;
              display: flex;
              justify-content: space-between;
            ">
                <h3 class="my-5">Filtro de Productos</h3>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-autocomplete outlined dense label="Proveedor" v-model="filter.providers" :items="providers"
                          item-text="ProviderName" attach item-value="ID_Provider"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete outlined dense label="Familia" v-model="filter.family" :items="families"
                          item-text="name" attach item-value="id"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete outlined dense label="Categoría" v-model="filter.category"
                          :items="filteredCategories" item-text="name" attach item-value="id"
                          :disabled="!filter.family"></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-autocomplete outlined dense label="Subcategoría" v-model="filter.subcategory"
                          :items="filteredSubcategories" attach item-text="name" item-value="id"
                          :disabled="!filter.category"></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="d-flex justify-space-between mt-1 mb-4">
                    <v-btn @click.prevent="clearFilters" class="btnclean">
                      <v-icon class="mr-2">mdi-filter-remove</v-icon> Limpiar
                    </v-btn>
                    <v-btn @click.prevent="filterProductsDt" class="btn-add">
                      <v-icon class="mr-2">mdi-filter-plus</v-icon> Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!-- Filter -->
            <h3 class="mt-3 ml-6 headerSize">{{ counter }} - Productos/Servicios Agregados </h3>
            <div class="space-div d-flex justify-space-between">
              <div>
                <v-btn v-if="showdeselect" class="btn-add mb-1" @click="deselectAll"><v-icon
                    class="mr-2">mdi-checkbox-blank-outline</v-icon>
                  Deseleccionar todas</v-btn>
                <v-btn v-else @click="selectAll" class="btn-add mb-1"><v-icon class="mr-2">mdi-check-all</v-icon> Seleccionar
                  todas</v-btn>
                <v-btn class="fs-14 btnclean mb-1" @click.prevent.stop="showFilter = !showFilter">
                  <v-icon class="mr-2">mdi-filter</v-icon> Filtrar</v-btn>
              </div>
              <v-btn class="btnsave fs-14 mb-1 mr-4" @click.prevent="addProducts">
                Agregar
              </v-btn>
            </div>
            <!-- Table 4 Start - ProductsToBill -->
            <GeneralFilter v-model="filterOfGeneralFilter" entity="producto"
              :filterEndpoint="$API.priceList.getProductsListFiltered" :search="{filter: filterOfGeneralFilter || null,family: filter.family || null,category: filter.category || null,subcategory: filter.subcategory || null,business: search.business,branch: search.branch}" @emptyFilter="getProducts"
              @filtered="filterHandlerProductsToBill" v-if="typeRadioProductService == 'product'">
              <block v-if="loadingTable"></block>
              <DxDataGrid :data-source="productsToBill" key-expr="ID_Item"
                :show-row-lines="showRowLines" @exporting="onExporting" :show-borders="showBorders"
                :row-alternation-enabled="rowAlternationEnabled" :column-auto-width="true" style="z-index: 100;">
                <DxExport :enabled="true" />

                
                <DxColumn :width="40" cell-template="show-template" caption=""></DxColumn>
                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5]" />
                <DxPaging :page-size="5" />
                <DxColumn data-field="ID_Item" caption="ID de Producto"></DxColumn>
                <DxColumn data-field="BarCode" caption="Codigo Alterno"></DxColumn>
                <DxColumn data-field="ItemName" caption="Nombre de Producto"></DxColumn>
                <DxColumn data-field="ItemPriceFormatted" caption="Precio"> </DxColumn>
                <template #show-template="{ data }">
                  <div class="d-flex justify-center align-center checkboxSize">
                    <v-checkbox v-model="data.data.checkbox" @click="showProduct(data)">
                    </v-checkbox>
                  </div>
                </template>
              </DxDataGrid>
            </GeneralFilter>
            <!-- Table 4 End - ProductsToBill -->

            <!-- Table 4.5 Start - ServicesToBill -->
            <GeneralFilter v-model="filterOfGeneralFilter" entity="service"
              :filterEndpoint="$API.priceList.getServicesListFiltered" :search="{filter: filterOfGeneralFilter || null,family: filter.family || null,category: filter.category || null,subcategory: filter.subcategory || null,business: search.business,branch: search.branch}" 
              @emptyFilter="getServices" class="tablesize"
              @filtered="filterHandlerProductsToBill" v-else>
              <block v-if="loadingTable"></block>
              <DxDataGrid :data-source="productsToBill" key-expr="ID_Service"
                :show-row-lines="showRowLines" @exporting="onExporting" :show-borders="showBorders"
                :row-alternation-enabled="rowAlternationEnabled" :column-auto-width="true" :scrolling="{useNative:true}">
                <DxExport :enabled="true" />
              
                <DxColumn :width="40" cell-template="show-template" caption=""></DxColumn>
                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5]" />
                <DxPaging :page-size="5" />
                <DxColumn data-field="CodeService" caption="ID de Servicio"></DxColumn>
                <DxColumn data-field="NameService" caption="Nombre de Servicio"></DxColumn>
                <DxColumn data-field="Name" caption="Nombre"></DxColumn>
                <DxColumn data-field="TypeServiceFormatted" caption="Tipo de Servicio"></DxColumn>
                <DxColumn data-field="PriceFormatted" caption="Precio"></DxColumn>
                <template #show-template="{ data }">
                  <div class="d-flex justify-center align-center checkboxSize">
                    <v-checkbox v-model="data.data.checkbox" @click="showProduct(data)">
                    </v-checkbox>
                  </div>
                </template>
              </DxDataGrid>
              &nbsp;
            </GeneralFilter>
            <!-- Table 4.5 End - ServicesToBill -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal relacionar lista de precio a clientes -->
    <v-dialog v-model="showRelateClientPrice" width="80%">
      <v-card sm="6">
        <v-btn elevation="0" color="red" class="close-btn" @click.prevent="showRelateClientPrice = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          <h2 class="mt-3">Listado de Clientes/Negocios</h2>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="typeRadioClientBusiness" class="mt-2">
            <v-radio label="Clientes" value="client" checked="true"></v-radio>
            <v-radio label="Negocios" value="business"></v-radio>
          </v-radio-group>

          <!-- Table 5 Start - Business -->
          <GeneralFilter entity="lista" :filterEndpoint="$API.customers.customersFiltered" :search="search"
            @emptyFilter="getCustomers" @filtered="filterHandlerCustomers" v-if="typeRadioClientBusiness == 'business'">
            <block v-if="loadingTable"></block>
            <DxDataGrid class="isScrolledByGeneralFilter" :data-source="customers" key-expr="ID_Customer"
              :column-auto-width="true" :show-borders="showBorders" :row-alternation-enabled="rowAlternationEnabled">
              <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 15]" />
              <DxPaging :page-size="10" />
              <DxColumn :width="50" cell-template="show-template" caption=""></DxColumn>
              <DxColumn data-field="ID_Customer" caption="ID"></DxColumn>
              <DxColumn data-field="BusinessName" caption="Nombre del negocio"></DxColumn>
              <DxColumn data-field="BusinessMobileNumber" caption="Celular"></DxColumn>
              <DxColumn data-field="BusinessPhone" caption="Télefono"></DxColumn>
              <DxColumn data-field="BusinessEmail" caption="Correo"></DxColumn>
              <DxColumn data-field="LegalName" caption="Nombre Legal"></DxColumn>
              <DxColumn data-field="LegalAddress" caption="Dirección Legal"></DxColumn>
              <DxColumn data-field="LegalNRC" caption="NRC"></DxColumn>
              <DxColumn data-field="PriceListName" caption="Lista de Precio"></DxColumn>

              <template #show-template="{ data }">
                <div class="d-flex justify-center align-center checkboxSize">
                  <v-checkbox v-model="data.data.checkbox" @click="showClients(data.data)">
                  </v-checkbox>
                </div>
              </template>

            </DxDataGrid>
          </GeneralFilter>
          <!-- Table 5 End - Business -->

          <!-- Table 6 Start - Client -->
          <GeneralFilter entity="lista" :filterEndpoint="$API.customers.customersFiltered" :search="search"
            @emptyFilter="getCustomers" @filtered="filterHandlerCustomers" v-else>
            <block v-if="loadingTable"></block>
            <DxDataGrid class="isScrolledByGeneralFilter" :show-borders="showBorders"
              :row-alternation-enabled="rowAlternationEnabled" :data-source="customers" key-expr="ID_Customer"
              :column-auto-width="true">
              <DxPage :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 15]" />
              <DxPaging :page-size="10" />
              <DxColumn :width="50" cell-template="show-template" caption=""></DxColumn>
              <DxColumn data-field="ID_Customer" caption="ID"></DxColumn>
              <DxColumn data-field="FirstName" caption="Nombre Legal"></DxColumn>
              <DxColumn data-field="LastName" caption="Apellido"></DxColumn>
              <DxColumn data-field="LegalAddress" caption="Dirección Legal"></DxColumn>
              <DxColumn data-field="MobileNumber" caption="Celular"></DxColumn>
              <DxColumn data-field="PhoneNumber" caption="Télefono"></DxColumn>
              <DxColumn data-field="Email" caption="Correo"></DxColumn>
              <DxColumn data-field="dui" caption="DUI o NIT"></DxColumn>
              <DxColumn data-field="PriceListName" caption="Lista de Precio"></DxColumn>

              <template #show-template="{ data }">
                <div class="d-flex justify-center align-center checkboxSize">
                  <v-checkbox v-model="data.data.checkbox" @click="showClients(data.data)">
                  </v-checkbox>
                </div>
              </template>

            </DxDataGrid>
          </GeneralFilter>
          <!-- Table 6 End - Client -->

          <div class="d-flex justify-end">
            <v-btn small class="btn-add  btn-link py-4" @click.prevent.stop="showAlertClientLinkList()">
              <v-icon class="mr-2">mdi-file-document-check</v-icon> Vincular Lista de Precios
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>
  </v-expansion-panel>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxPager,
  DxExport,
} from "devextreme-vue/data-grid";
import Alerts from "@/components/Alerts"
import { mapState } from "vuex";
import { printToExcel } from "@/helpers/printToexcel";
import GeneralFilter from "@/components/GeneralFilter.vue";
import Block from "@/components/Block";
//import UploadXLSX from "../UploadXLSX.vue"; 

export default {
  name: "PriceList",
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxPager,
    Alerts,
    DxExport,
    GeneralFilter,
    Block,
    //UploadXLSX
  },
  props: ["win"],
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      valid: false,
      showPrices: false,
      showPriceList: false,
      showRelateClientPrice: false,
      productsprev: [],
      tab: null,
      panel: 0,
      checkbox: true,
      discountpercent: 0,
      form: {},
      exportData: false,
      priceList: [],
      showList: false,
      productsToBill: [],
      ClientsToLink: [],
      ClientsToLinkSend: [],
      productsToShow: [],
      productsToShowOld: [],
      filterProducts: {},
      items: [],
      filter: {
        family: null,
        category: null,
        subcategory: null,
        providers: null,
      },
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      business: null,
      branch: null,
      update: false,
      showdeselect: false,
      counter: 0,
      counterClient: 0,
      showFilter: false,
      families: [],
      categories: [],
      subcategories: [],
      providers: [],
      renderN: 0,
      customers: [],
      typeRadioClientBusiness: "client",
      typeRadioProductService: "product",
      search: {
        filter: null,
      },
      savedProductsToShow: [],
      loading: false,
      loadingTable: false,
      filterOfGeneralFilter: null,
      typeService: [
        {
          ID: "PLT",
          Name: "Test de Laboratorio",
        },
        {
          ID: "OTR",
          Name: "Otro",
        },
        {
          ID: "VAC",
          Name: "Vacuna",
        },
        {
          ID: "MED",
          Name: "Medicamento",
        },
      ],
      Currency: null,
      productsFiltered: true,
      productsToShowSource: [],
      EditOriginalPrice: false,
    };
  },
  watch: {
    typeRadioClientBusiness() {
      if (this.typeRadioClientBusiness == "business") {
        this.search.type = 1;
        this.getCustomers();
      } else {
        this.search.type = 0;
        this.getCustomers();
      }
    },
    typeRadioProductService() {
      if (this.typeRadioProductService == "product") {
        this.getProducts();
      } else {
        this.getServices();
      }
    },
    showPrices: function (newValue) {
      if (!newValue) {
        this.productsToBill.map((element) => {
          element.checkbox = false;
        });
        this.showpriceskey++;
        this.counter = 0;
      }
    },
    showRelateClientPrice(newValue) {
      if (!newValue) {
        this.resetCustomerCheckboxes();
      } else {
        this.ClientsToLinkSend = []
      }
    },
    "data.data.ItemPriceDiscountPercent"(newVal) {
      // Check if the new value exceeds the maximum discount percentage
      if (newVal > this.maxDiscountPercent) {
        // If it does, set it to the maximum allowed value
        this.data.data.ItemPriceDiscountPercent = this.maxDiscountPercent;
      }
    },
  },
  computed: {
    ...mapState(["CurrentBranch"]),
    filteredCategories() {
      return this.filter.family
        ? this.categories.filter(
          (category) => category.id_family == this.filter.family
        )
        : [];
    },
    columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
    filteredSubcategories() {
      return this.filter.category
        ? this.subcategories.filter(
          (subcategory) => subcategory.id_cat == this.filter.category
        )
        : [];
    },
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    maxDiscountPercent() {
      return 100;
    },
    clientOrBusiness() {
      return this.typeRadioClientBusiness == 'business' ? 'Negocios' : 'Clientes'
    }
  },
  mounted() {
    this.Currency = JSON.parse(localStorage.getItem('branch')).Currency;
    this.business =
      JSON.parse(localStorage.getItem("user")).businessid != undefined
        ? JSON.parse(localStorage.getItem("user")).businessid
        : 0;
    this.branch =
      JSON.parse(localStorage.getItem("user")).branch != undefined
        ? JSON.parse(localStorage.getItem("user")).branch
        : 0;
    this.filterProducts = {
      filter: "pricelist",
      business: this.business,
      branch: this.branch,
    };
    this.getAllPriceList();
    this.getProducts();
    this.getFamilies();
    this.getCategories();
    this.getSubs();
    this.getAllProviders();
    this.search.business = JSON.parse(localStorage.getItem("user")).businessid;
    this.search.branch = JSON.parse(localStorage.getItem("user")).branch;
    this.search.type = 0;
    this.getCustomers();
  },
  methods: {
    showWarningBeforeDelete() {
      this.showAlert(
        "question", 
        "Advertencia", 
        "¿Esta seguro que desea eliiminar la lista de precios seleccionada?", 
        "deletePriceList"
      )
    },
    selectAllText(event) {
      event.target.select();
    },
    getCustomers() {
      this.loadingTable = true;
      this.$API.customers.getCustomersHistory(this.search).then((response) => {
        this.customers = response;
        this.customerCheckboxes();
      }).finally(() => { 
        this.loadingTable = false; 
      });
    },
    onExporting(e) {
      printToExcel(e, "Lista de Precios ");
    },
    async onExportingLP() {
      //await this.exportN();
      //this.exportData = true;
    },
    async onExportingP() {
      this.loading = true
      this.exportData = true
      setTimeout(() => {
        const data = this.$refs.priceListref.instance;
        printToExcel({ component: data }, "Lista de Productos");
      }, 200);
      setTimeout(() => {
        this.exportData = false
        this.loading = false
      }, 300)
      //printToExcel(e, "Lista de Precios");
      //await this.exportN();
    },
    filterProductsDt() {
      /*this.productsToBill = this.productsToShowOld;
      if (this.filter.family != null) {
        this.productsToBill = this.productsToBill.filter(
          (product) => product.family == this.filter.family
        );
      }
      if (this.filter.category != null) {
        this.productsToBill = this.productsToBill.filter(
          (product) => product.category == this.filter.category
        );
      }
      if (this.filter.subcategory != null) {
        this.productsToBill = this.productsToBill.filter(
          (product) => product.subcategory == this.filter.subcategory
        );
      }
      if (this.filter.providers != null) {
        this.productsToBill = this.productsToBill.filter(
          (product) => product.ID_Provider == this.filter.providers
        );
      }*/
      let tmpSearch = {
        filter: this.filterOfGeneralFilter || null,
        family: this.filter.family || null,
        category: this.filter.category || null,
        subcategory: this.filter.subcategory || null,
        business: this.search.business,
        branch: this.search.branch
      }

      if(this.typeRadioProductService == 'product') {
        this.$API.priceList.getProductsListFiltered(tmpSearch)
        .then(response => {
          this.productsToBill = response.map((product) => {
            product.ItemPriceFormatted = `${this.CurrentBranch.Currency}${parseFloat(product.ItemPrice).toFixed(2)}`
            product.ItemPriceFormatted = `${this.CurrentBranch.Currency}${parseFloat(product.ItemPrice).toFixed(2)}`
            return {
              ...product,
            }
          })
        })
      } 
      else {
        this.$API.priceList.getServicesListFiltered(tmpSearch)
        .then(response => {
          this.productsToBill = response.map((product) => {
            product.ItemPriceFormatted = `${this.CurrentBranch.Currency}${parseFloat(product.ItemPrice).toFixed(2)}`
            product.ItemPriceFormatted = `${this.CurrentBranch.Currency}${parseFloat(product.ItemPrice).toFixed(2)}`
            return {
              ...product,
            }
          })
        })
      }
      
      this.showFilter = false;
    },
    clearFilters() {
      this.filter.family = null;
      this.filter.category = null;
      this.filter.subcategory = null;
      this.filter.providers = null;
      this.productsToBill = this.productsToShowOld;
    },
    addProducts() {
      this.productsToBill.forEach((element) => {
        if (element.checkbox) {
          element.ID_Item = element.ID_Item || element.CodeService;
          element.ItemNameToBill = element.ItemName || element.NameService;
          
          if(element.ItemPrice != undefined) {
            element.ItemOriginalPrice = element.ItemPrice  
          }

          if(element.Price != undefined) {
            element.ItemOriginalPrice = element.Price
          }
          
          element.ItemPriceDiscountPercent = parseFloat(0);
          element.ItemPriceDiscountAmount = parseFloat(parseFloat(0).toFixed(2));
          element.DefaultRange = true;
          element.ItemNewPrice = element.ItemOriginalPrice
          this.productsprev.push(element);
        }
      });
      this.productsprev.forEach((element) => {
        if (!this.productsToShow.find((i) => i.ID_Item == element.ID_Item)) {
          element.Active = this.form.Active;
          this.productsToShow.push(element);
          this.productsToShowSource = [ ...this.productsToShow ];
        }
      });

      //  this.productsToShow.push(...this.productsprev);
      this.productsToBill.map((element) => {
        element.checkbox = false;
      });
      this.counter = 0;
      this.showPrices = false;
      this.showpriceskey++;
      this.productsprev = [];
    },
    addProduct(data) {
      const { index: existingProductIndex, productFound } = this.findBiggestUniqueIDIndex(data)

      const newProduct = productFound || { ...data };
      newProduct.DefaultRange = false;
      newProduct.InitialRange = newProduct.FinalRange + 1 || 1;
      newProduct.FinalRange = newProduct.FinalRange + 10 || 10;
      newProduct.uniqueID = Date.now(); // Add a unique identifier, should be good because is in milliseconds

      if (existingProductIndex !== -1) {
        this.productsToShow.splice( this.productsToShow.findIndex( current => current.ID_Item === newProduct.ID_Item ) + 1 + 1, 0, newProduct );
        this.productsToShowSource.splice( this.productsToShowSource.findIndex( current => current.ID_Item === newProduct.ID_Item ) + 1, 0, newProduct );
      } else {
        this.productsToShow.push(newProduct);
        this.productsToShowSource.push( newProduct );
      }

      this.orderProducts();
    },
    validateProductRanges() {
      let productsIds = []
      let hasIssue = false;

      this.productsToShow.map(({ ID_Item }) => {
        if (!productsIds.includes(ID_Item)) {
          productsIds.push(ID_Item);
        }
      })

      if (productsIds.length <= 0) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe agregar productos a la lista de precios"
        );
        hasIssue = true;
      }

      productsIds.forEach((id) => {
        let oneDefaultRange = 0;
        let products = this.productsToShow.filter(({ ID_Item }) => id == ID_Item)

        for (let product of products) {
          product.DefaultRange = product.DefaultRange || (product.InitialRange == null && product.FinalRange == null);
          if (product.DefaultRange && oneDefaultRange <= 1) {
            oneDefaultRange++;
            if (oneDefaultRange > 1) {
              this.showAlert(
                "warning",
                "Advertencia",
                "No pueden existir dos valores por defecto en la lista. Agregue rangos validos"
              );
              hasIssue = true;
            }
          }

          for (let productCompare of products) {
            if (product.ItemNewPrice == productCompare.ItemNewPrice 
            && product.InitialRange != productCompare.InitialRange && product.FinalRange != productCompare.FinalRange) {
              if (productCompare.DefaultRange) {
                this.showAlert(
                  "warning",
                  "Advertencia",
                  `El producto (${product.ItemName}) tiene el mismo precio por defecto que el asignado en el rango escalonado de ${product.InitialRange} a ${product.FinalRange}. Por favor, verifica los precios asignados.`
                )
                hasIssue = true;
              } 
              else {
                this.showAlert(
                  "warning",
                  "Advertencia",
                  `El producto (${product.ItemName}) tiene el mismo precio asignado en el rango escalonado de ${product.InitialRange} a ${product.FinalRange} que en otro rango escalonado. Por favor, verifica los precios asignados para evitar duplicidades.`
                )
                hasIssue = true;
              }
            }
          }
        }

        //Check for valid Initial Range and Final Range
        const sortedProducts = products.slice().sort((a, b) => a.InitialRange - b.InitialRange);
        for (let i = 1; i < sortedProducts.length; i++) {
          const currentProduct = sortedProducts[i];
          const previousProduct = sortedProducts[i - 1];

          if (currentProduct.InitialRange <= previousProduct.FinalRange) {
            this.showAlert(
              "warning",
              "Advertencia",
              `Se detectaron rangos superpuestos en ${currentProduct.ItemName}, corregir el rango entre ${currentProduct.InitialRange} y ${currentProduct.FinalRange}`
            );
            hasIssue = true;
          }
        }
      })

      return hasIssue;
    },
    findBiggestUniqueIDIndex(data) {
      let maxUniqueID = -1;
      let maxUniqueIDIndex = this.productsToShow.findIndex(item => item.ID_Item === data.ID_Item);
      let productFound = null

      for (let i = 0; i < this.productsToShow.length; i++) {
        const product = this.productsToShow[i];

        if (product.ID_Item === data.ID_Item && product.uniqueID > maxUniqueID) {
          maxUniqueID = product.uniqueID;
          maxUniqueIDIndex = i;
          productFound = { ...product }

        }
      }

      return { index: maxUniqueIDIndex, product: productFound };
    },
    orderProducts() {
      this.productsToShow.sort((a, b) => {
        // Order by ID_Item first
        if (a.ID_Item !== b.ID_Item) {
          return a.ID_Item - b.ID_Item;
        }

        // If ID_Item is the same, handle FinalRange
        if (a.FinalRange === null && b.FinalRange === null) {
          return 0; // both have null, maintain the order
        } else if (a.FinalRange === null) {
          return -1; // a has null, should come first
        } else if (b.FinalRange === null) {
          return 1; // b has null, should come first
        } else {
          // For non-null values, compare FinalRange
          return a.FinalRange - b.FinalRange;
        }
      });
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == 'cleanForm') {
        this.cleanData()
      }
    },
    reload() {
      this.getAllPriceList();
      this.getProducts();
      setTimeout(() => {
        window.scrollTo(0, this.$store.getters.getScrollTop);
      }, 300);
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    deselectAll() {
      this.productsToBill.forEach((element) => {
        element.checkbox = false;
      });
      this.showdeselect = false;
      this.counter = 0;
    },
    selectAll() {
      this.productsToBill.forEach((element) => {
        element.checkbox = true;
      });
      this.counter = this.productsToBill.length;
      this.showdeselect = true;
    },
    getAllPriceList() {
      this.$API.priceList
        .priceListHistory(this.filterProducts)
        .then((response) => {
          this.priceList = response;
        });
    },
    getProducts() {
      if (
        this.search.filterProducts == undefined ||
        this.search.filterProducts != this.filterProducts
      ) {
        this.search.filterProducts = JSON.stringify(this.filterProducts);
      }
      this.loadingTable = true;
      this.$API.priceList
        .getProductsListHistory(this.filterProducts)
        .then((response) => {
          this.productsToBill = response.map((product) => {
            product.ItemNewPrice = product.ItemPrice
            product.ItemPriceFormatted = `${this.CurrentBranch.Currency}${parseFloat(product.ItemPrice).toFixed(2)}`
            return {
              ...product,
            }
          });
          this.productsToShowOld = response;
          // add a checkbox to each product
          this.productsToBill.forEach((product) => {
            product.checkbox = false;
          });
        }).finally(() => {
          this.loadingTable = false;
        });
    },
    getServices() {
      this.loadingTable = true;
      this.$API.priceList
        .getServicesListHistory(this.filterProducts)
        .then((response) => {
          this.productsToBill = response.map((service) => {
            const newType = this.typeService.find(({ID}) =>  {
              return ID == service.TypeService
            })
            service.PriceFormatted = `${this.CurrentBranch.Currency}${parseFloat(service.Price).toFixed(2)}`
            service.TypeServiceFormatted = `${newType.Name}`
            return {
              ...service,
            }
          });
          this.productsToShowOld = response;
          // add a checkbox to each product
          this.productsToBill.forEach((product) => {
            product.checkbox = false;
          });
        }).finally(() => {
          this.loadingTable = false;
        });
    },
    getAllProviders() {
      let search = {};
      search.business =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      search.branch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.$API.providers.getProviders(search).then((response) => {
        this.providers = response;
      });
    },
    showClients(data) {
      const index = this.ClientsToLink.indexOf(data.ID_Customer);

      if (index === -1) {
        this.ClientsToLink.push(data.ID_Customer);
      } else {
        this.ClientsToLink.splice(index, 1);
      }
    },
    showProduct() {
      this.counter = 0;
      this.productsToBill.forEach((element) => {
        if (element.checkbox) {
          this.counter++;
        }
      });
      // this.productsToShow.find(i => i.ID_Item == data.data.ID_Item).checkbox == true ? false : true;

      /*   this.productsprev.push({
        ID_Item: data.data.ID_Item,
        ItemName: data.data.ItemName,
        ItemOriginalPrice: data.data.ItemPrice,
        ItemPriceDiscountPercent: 0,
        ItemPriceDiscountAmount: 0,
        ItemNewPrice: data.data.ItemPrice,
        ItemCurrency: '$',
        ID_Branch: data.data.ID_Branch,
        IsGlobalBranch: data.data.IsGlobalBranch,
        ID_Business: data.data.ID_Business
      })
    this.productsToShow.push({
        ID_Item: data.data.ID_Item,
        ItemName: data.data.ItemName,
        ItemOriginalPrice: data.data.ItemPrice,
        ItemPriceDiscountPercent: 0,
        ItemPriceDiscountAmount: 0,
        ItemNewPrice: data.data.ItemPrice,
        ItemCurrency: '$',
        ID_Branch: data.data.ID_Branch,
        IsGlobalBranch: data.data.IsGlobalBranch,
        ID_Business: data.data.ID_Business
      }) */
      //this.productsToShow.push({...data.data,discount : 0, discountpercent: 0,newprice : 0})
    },
    getFamilies() {
      this.$API.itemtypes.getFamilies().then((response) => {
        this.families = response;
      });
    },
    getCategories() {
      this.$API.itemtypes.getCategories().then((response) => {
        this.categories = response;
      });
    },
    getSubs() {
      this.$API.itemtypes.getSubs().then((response) => {
        this.subcategories = response;
      });
    },
    handleChanged(priceListSelected) {
      this.showPriceList = false;
      this.loading = true;
      if (!priceListSelected.Active) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe existir al menos una lista de precios activa"
        );
        this.priceList = this.priceList.map((i) => {
          if (i.id == priceListSelected.id) {
            i.Active = true;
          }

          return i;
        });
        return;
      }
      let data = {
        id: priceListSelected.id,
      };
      this.$API.priceList
        .changuePriclistSelected(data)
        .then(() => {
          this.showAlert(
            "success",
            "Éxito",
            "Lista de precios activada con éxito"
          );
          this.$store.commit("setChanguePriceList", true);
          this.cleanData();
          this.getAllPriceList();
        })
        .catch(() => {
          this.showAlert(
            "warning",
            "Advertencia",
            "Error al activar la lista de precios"
          );
          this.getAllPriceList();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showDecimals(price) {
      return parseFloat(price).toFixed(4)
    },
    showProductOld(data) {
      this.loading = true;
      this.update = true;
      let params = {
        business:
          JSON.parse(localStorage.getItem("user")).businessid != undefined
            ? JSON.parse(localStorage.getItem("user")).businessid
            : 0,
        branch:
          JSON.parse(localStorage.getItem("user")).branch != undefined
            ? JSON.parse(localStorage.getItem("user")).branch
            : 0,
        PriceListName: data.data.id,
      };

      this.$API.priceList.itemsFromList(params).then((response) => {
        this.loading = false;
        this.productsToShow = response.map((i) => {
          return {
            ID_Item: i.ID_Item,
            ItemName: i.ItemName || i.NameService,
            ItemNameToBill: i.ItemName || i.NameService,
            ItemOriginalPrice: i.ItemOriginalPrice,
            ItemPriceDiscountPercent: i.ItemPriceDiscountPercent,
            ItemPriceDiscountAmount: i.ItemPriceDiscountAmount,
            ItemNewPrice: i.ItemNewPrice,
            ItemCurrency: i.ItemCurrency,
            ID_Branch: i.ID_Branch,
            IsGlobalBranch: i.IsGlobalBranch,
            ID_Business: i.ID_Business,
            InitialRange: i.InitialRange,
            FinalRange: i.FinalRange,
            DefaultRange: i.DefaultRange,
            Active: data.data.Active,
            uniqueID: i.uniqueID,
            BarCode: i.BarCode
          };
        });

        this.productsToShowSource = [ ...this.productsToShow ];
        this.form.pricelistname = data.data.id;
        this.form.Active = data.data.Active;
        this.saveDataPromise();
      })
      .catch(() => {
        this.loading = false;
      })
    },
    removeRow(data) {
      const products = this.productsToShow.filter((product) => product.ID_Item == data.data.ID_Item);
      if (products.length > 1 && data.data.DefaultRange) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe eliminar los demás rangos de precios antes del por Defecto"
        );
      } else {
        this.productsToShow.splice( this.productsToShow.findIndex( current => {
          return (current.ID_Item === data.data.ID_Item && current.InitialRange == data.data.InitialRange && current.FinalRange == data.data.FinalRange)
        } ), 1 );
        this.productsToShowSource.splice( this.productsToShowSource.findIndex( current => {
          return (current.ID_Item === data.data.ID_Item && current.InitialRange == data.data.InitialRange && current.FinalRange == data.data.FinalRange)
        } ), 1 );
      }
    },
    resetData() {
      this.productsToShow = [];
      this.productsToShowSource = [];
      this.form.pricelistname = null;
      this.update = false;
      this.getAllPriceList("productsToShow");
    },
    changeDis(event, data) {
      let OriginalPrice = parseFloat(data.data.ItemOriginalPrice);

      data.data.ItemPriceDiscountPercent = (
        (parseFloat(event.target.value) / OriginalPrice) *
        100
      ).toFixed(4);

      data.data.ItemNewPrice = parseFloat(
        OriginalPrice - parseFloat(event.target.value)
      ).toFixed(4);

      this.productsToShowSource = this.productsToShowSource.map((i) => {
        if (i.ID_Item == data.data.ID_Item && i.uniqueID == data.data.uniqueID) {
          i.ItemPriceDiscountPercent = data.data.ItemPriceDiscountPercent;
          i.ItemPriceDiscountAmount = data.data.ItemPriceDiscountAmount;
          i.ItemNewPrice = data.data.ItemNewPrice;
        }

        return i;
      });

      this.productsToShow = this.productsToShow.map((i) => {
        if (i.ID_Item == data.data.ID_Item && i.uniqueID == data.data.uniqueID) {
          i.ItemPriceDiscountPercent = data.data.ItemPriceDiscountPercent;
          i.ItemPriceDiscountAmount = data.data.ItemPriceDiscountAmount;
          i.ItemNewPrice = data.data.ItemNewPrice;
        }

        return i;
      });

      this.savedProductsToShow = [...this.productsToShow]
      //render all table to show new price
      //  this.renderN++;
    },
    changeDisP(event, data) {
      let OriginalPrice = parseFloat(data.data.ItemOriginalPrice);

      // Ensure the entered discount percentage is within the valid range [0, 100]
      let discountPercent = parseFloat(event.target.value);
      if (isNaN(discountPercent)) {
        // Handle non-numeric input (letters or symbols)
        discountPercent = 0;
      } else if (discountPercent < 0) {
        // Ensure it doesn't go below 0%
        discountPercent = 0;
      } else if (discountPercent > 100) {
        // Limit it to a maximum of 100%
        discountPercent = 100;
      }

      data.data.ItemPriceDiscountPercent = discountPercent;

      data.data.ItemPriceDiscountAmount = (
        (discountPercent / 100) *
        OriginalPrice
      ).toFixed(2);

      data.data.ItemNewPrice = parseFloat(
        OriginalPrice - parseFloat(data.data.ItemPriceDiscountAmount)
      ).toFixed(2);

      this.productsToShow = this.productsToShow.map((i) => {
        if (i.ID_Item == data.data.ID_Item && i.uniqueID == data.data.uniqueID) {
          i.ItemPriceDiscountPercent = data.data.ItemPriceDiscountPercent;
          i.ItemPriceDiscountAmount = data.data.ItemPriceDiscountAmount;
          i.ItemNewPrice = data.data.ItemNewPrice;
        }

        return i;
      });
      this.productsToShowSource = this.productsToShowSource.map((i) => {
        if (i.ID_Item == data.data.ID_Item && i.uniqueID == data.data.uniqueID) {
          i.ItemPriceDiscountPercent = data.data.ItemPriceDiscountPercent;
          i.ItemPriceDiscountAmount = data.data.ItemPriceDiscountAmount;
          i.ItemNewPrice = data.data.ItemNewPrice;
        }

        return i;
      });

      this.savedProductsToShow = [...this.productsToShow]

      //render all table to show new price
      //  this.renderN++;
    },
    changeIntRange(event, data, type) {
      const parsedValue = parseFloat(event);

      this.productsToShow.forEach((i) => {
        if (i.ID_Item === data.data.ID_Item && i.uniqueID === data.data.uniqueID) {
          if (type === 0) {
            i.InitialRange = parsedValue;
          } else if (type === 1) {
            i.FinalRange = parsedValue;
          }
        }
      });

      this.productsToShowSource.forEach((i) => {
        if (i.ID_Item === data.data.ID_Item && i.uniqueID === data.data.uniqueID) {
          if (type === 0) {
            i.InitialRange = parsedValue;
          } else if (type === 1) {
            i.FinalRange = parsedValue;
          }
        }
      });
    },
    changeDisO(event, data) {
      let discountPercent = parseFloat(data.data.ItemPriceDiscountPercent)
      //let discountAmount = parseFloat(data.data.ItemPriceDiscountAmount)
      let OriginalPrice = parseFloat(event.target.value);

      data.data.ItemPriceDiscountAmount = (
        (discountPercent / 100) *
        OriginalPrice
      ).toFixed(6);

      data.data.ItemNewPrice = parseFloat(
        OriginalPrice - parseFloat(data.data.ItemPriceDiscountAmount)
      ).toFixed(6);

      this.productsToShow = this.productsToShow.map((i) => {
        if (i.ID_Item == data.data.ID_Item && i.uniqueID == data.data.uniqueID 
          && i.InitialRange == data.data.InitialRange && i.FinalRange == data.data.FinalRange
        ) {
          i.ItemOriginalPrice = OriginalPrice
          i.ItemPriceDiscountPercent = data.data.ItemPriceDiscountPercent;
          i.ItemPriceDiscountAmount = data.data.ItemPriceDiscountAmount;
          i.ItemNewPrice = data.data.ItemNewPrice;
        }

        return i;
      });
      this.productsToShowSource = this.productsToShowSource.map((i) => {
        if (i.ID_Item == data.data.ID_Item && i.uniqueID == data.data.uniqueID
        && i.InitialRange == data.data.InitialRange && i.FinalRange == data.data.FinalRange
        ) {
          i.ItemOriginalPrice = OriginalPrice
          i.ItemPriceDiscountPercent = data.data.ItemPriceDiscountPercent;
          i.ItemPriceDiscountAmount = data.data.ItemPriceDiscountAmount;
          i.ItemNewPrice = data.data.ItemNewPrice;
        }

        return i;
      });

      this.savedProductsToShow = [...this.productsToShow]
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "clientLinkList") {
        this.addPriceListToClient();
      }

      if (this.alert.options == "deletePriceList") {
        this.$API.priceList.deletePriceList({ PriceListName: this.form.pricelistname })
          .then(response => {
            if (response?.status == "OK") {
              this.showAlert("success", "Éxito", "La lista de precios seleccionada ha sido eliminada.", "cleanForm")
            }
          })
      }
    },
    cleanData() {
      this.productsToShow = [];
      this.productsToShowSource = [];
      this.search.business = JSON.parse( localStorage.getItem( "user" ) ).businessid;
      this.search.branch = JSON.parse( localStorage.getItem( "user" ) ).branch;
      this.search.type = 0;
      this.search.filter = null;
      this.savedProductsToShow = [];
      this.form.pricelistname = null;
      this.form.Active = false;
      this.update = false;
      this.getAllPriceList();
    },
    addNewPriceList() {
      this.loading = true;

      if (!this.valid) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe llenar los campos requeridos"
        );
        this.loading = false;
        return;
      }
      if (this.validateProductRanges()) {
        this.loading = false;
        return;
      }

      var action = this.update == true ? 1 : 0;
      this.productsToShow.PriceListName = this.form.pricelistname;
      let iduser = JSON.parse(localStorage.getItem("user")).id;

      for( const element of this.productsToShow ) {
        const { ID_Item, ItemNewPrice, ItemPriceDiscountAmount, ItemPriceDiscountPercent } = element;

        for( const i in this.savedProductsToShow ) { 
          if( this.savedProductsToShow[ i ].ID_Item === ID_Item ) {
            this.savedProductsToShow[ i ].ItemNewPrice = ItemNewPrice;
            this.savedProductsToShow[ i ].ItemPriceDiscountAmount = ItemPriceDiscountAmount;
            this.savedProductsToShow[ i ].ItemPriceDiscountPercent = ItemPriceDiscountPercent;
          }
        }
      }

      const diffArray = this.productsToShow.filter( current => !this.savedProductsToShow.some( current2 => current2.ID_Item === current.ID_Item ) );

      this.savedProductsToShow.push( ...diffArray );

      this.$API.priceList
        .addPriceList({
          Action: action,
          PriceListName: this.form.pricelistname,
          ID_User_Insert: iduser,
          items: this.productsToShow,
          Active: this.form.Active ? 1 : 0,
        })
        .then(() => {
          this.showAlert(
            "success",
            "Exito",
            "Lista de precios guardada con éxito"
          )
          this.resetData();
        })
        .catch((err) => {
          console.log(err);
          this.showAlert(
            "warning",
            "Advertencia",
            "Error al guardar la lista de precios"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // Generar lista de negocio o cliente
    showAlertClientLinkList() {
      this.showRelateClientPrice = !this.showRelateClientPrice;
      this.showAlert(
        "question2",
        "Confirmación",
        `¿Está seguro de que desea relacionar a estos ${this.clientOrBusiness} con la Lista de Precios?`,
        "clientLinkList"
      );
    },
    addPriceListToClient() {
      const linkData = {
        ClientsToLink: this.ClientsToLinkSend,
        pricelistname: this.form.pricelistname,
      };
      this.$API.priceList
        .linkPriceList(linkData)
        .then(() => {
          this.loading = false;
          this.showAlert(
            "success",
            "Exito",
            "Lista de precios relacionada con éxito"
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "warning",
            "Advertencia",
            "La lista de precios no fue relacionada"
          );
        })
        .finally(() => {
          this.getCustomers();
          this.ClientsToLinkSend = []
        });
    },
    filterHandlerPriceList(response) {
      this.priceList = response;
    },
    filterHandlerProductsToBill(response) {
      this.productsToBill = response.map((product) => {
        product.ItemPriceFormatted = `${this.CurrentBranch.Currency}${parseFloat(product.ItemPrice).toFixed(2)}`
        product.ItemPriceFormatted = `${this.CurrentBranch.Currency}${parseFloat(product.ItemPrice).toFixed(2)}`
        return {
          ...product,
        }
      });
    },
    filterHandlerProductsToShow(response) {
      this.productsToShowSource = response;
    },
    filterHandlerCustomers(response) {
      this.customers = response;
      this.customerCheckboxes();
    },
    customerCheckboxes() {
      if (this.ClientsToLink.length <= 0) return;
      this.customers.forEach((customer) => {
        customer.checkbox = this.ClientsToLink.find((ID) => ID == customer.ID_Customer);
      });
    },
    resetCustomerCheckboxes() {
      this.ClientsToLinkSend = [...this.ClientsToLink];
      this.ClientsToLink = []
      this.customers.map((customer) => {
        if (customer.checkbox) customer.checkbox = false;
      })
    },
    filterProductsToShow({ filter }) {
      let filterLower = filter.toLowerCase();
      let filteredProducts = this.productsToShowSource.filter(
        (product) =>
          product.ID_Item.toString().toLowerCase().includes(filterLower) ||
          product.ItemName.toString().toLowerCase().includes(filterLower) ||
          product.ItemOriginalPrice.toString().includes(filterLower) ||
          product.ItemNewPrice.toString().includes(filterLower) ||
          product.BarCode?.toString().includes(filterLower)
      );
      
      return new Promise((resolve) => {
        resolve(filteredProducts);
      });
    },
    saveDataPromise() {
      this.savedProductsToShow = this.productsToShow
      this.search.productsToShow = this.productsToShow
    },
    emptyFilter() {
      this.productsToShow = this.savedProductsToShow
      this.productsToShowSource = this.savedProductsToShow
    },
    onRowPrepared(e) {
      if (e.rowType === "data") {
        if (e.data.DefaultRange) {
          e.cellElement.style.cssText = "color: white; background-color: red";
          e.rowElement.classList.add("my-class");
          e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
        }
      }
    },
  },
};
</script>

<style scoped>
.fs-10 {
  font-size: 10px !important;
}

.input-group-prepend {
  display: flex;
}

.input-group-prepend {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 3px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0 5px;
  font-size: 14px;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group>.form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
  margin-right: 5px ;
}

.btn-position {
  position: relative;
  top: 45px;
  z-index: 9999;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.tabs {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
}

.btndelete {
  font-family: bold !important;
  background-color: #e3191e !important;
  color: white;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.tabsB:hover {
  background-color: #0d47a1;
  cursor: pointer;
}

.tabsN {
  padding: 5px;
  background-color: rgba(60, 60, 60, 1);
  color: white;
  border-radius: 15px;
}

.space-div {
  margin-top: 2rem;
  margin-left: 1rem;
  z-index: 50;
}

.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}

.headerSize {
  font-size: 18px;
}

.checkboxSize {
  max-height: 30px;
}

.btn-link {
  font-size: 15px;
}

.boldProduct {
  background-color: #e3191e !important;
  color: white;
}

@media only screen and (max-width: 960px) {
  .space-div {
    margin-top: 1rem;
    position: relative;
  }
}

@media (min-height: 1460px) {
  .tablesize {
    max-height: 100%;
  }
}

@media (min-height: 1060px) and (max-height: 1459px) {
  .tablesize {
    max-height: 700px;
  }
}
@media (min-height: 930px) and (max-height: 1059px) {
  .tablesize {
    max-height: 600px;
  }
}
@media (min-height: 860px) and (max-height: 929px) {
  .tablesize {
    max-height: 500px;
  }
}
@media (min-height: 660px) and (max-height: 859px){
  .tablesize {
    max-height: 250px;
  }
}
@media (min-height: 460px) and (max-height: 659px) {
  .tablesize {
    max-height: 200px;
  }
}
@media (max-height: 459px) {
  .tablesize {
    max-height: 150px;
  }
}
</style>
