<template>
  <v-expansion-panel v-model="panel">
    <v-expansion-panel-header class="mt-2">
      Reportes de Inventario
      <template v-slot:actions>
        <v-btn class="mr-5" @click.native.stop="getFilterData()" icon small>
          <v-icon color="primary">mdi-reload</v-icon>
        </v-btn>
        <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
          <v-icon color="error">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab href="#tab-1" v-if="$store.getters['rolesUser/getArrayCustomRole']([242]) || $store.getters['rolesUser/getArrayCustomRole']([252]) || $store.getters['rolesUser/isAdminOrManager']">
          Existencias
          <v-icon>mdi-package</v-icon>
        </v-tab>
        
          <v-tab href="#tab-2" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
            Ajuste de inventario
            <v-icon>mdi-archive-eye</v-icon>
          </v-tab>
          <v-tab href="#tab-6" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
            Minimos / maximos
            <v-icon>mdi-archive-cog</v-icon>
          </v-tab>
          <!-- <v-tab href="#tab-3" v-if="$store.getters['rolesUser/getArrayCustomRole']([244]) || $store.getters['rolesUser/isAdminOrManager']">
            Promedio Ponderado
            <v-icon>mdi-archive-search</v-icon>
          </v-tab>
          <v-tab href="#tab-4" v-if="$store.getters['rolesUser/getArrayCustomRole']([245]) || $store.getters['rolesUser/isAdminOrManager']">
            PEPS
            <v-icon>mdi-table-check</v-icon>
          </v-tab>
          <v-tab href="#tab-5" v-if="$store.getters['rolesUser/getArrayCustomRole']([246]) || $store.getters['rolesUser/isAdminOrManager']">
            UEPS
            <v-icon>mdi-table-minus</v-icon>
          </v-tab> -->
          <v-tab href="#tab-7" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
            Solicitud de ajustes
            <v-icon>mdi-table-cog</v-icon>
          </v-tab>
          <v-tab href="#tab-8" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
            Verificación de ajustes
            <v-icon>mdi-table-edit</v-icon>
          </v-tab>
          <v-tab href="#tab-7" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
            Solicitud de ajustes
            <v-icon>mdi-table-cog</v-icon>
          </v-tab>
          <v-tab href="#tab-8" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
            Verificación de ajustes
            <v-icon>mdi-table-edit</v-icon>
          </v-tab>
       
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-1" v-if="$store.getters['rolesUser/getArrayCustomRole']([242]) || $store.getters['rolesUser/getArrayCustomRole']([252]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-1 position-relative ">
            <v-card-text class="pa-0"> 
              <SpecialInventoryReport v-if="SpecialInventoryReport == 1" :keyR="key" :datafilter="filterData"/>
              <InventoryReport v-else :key="key" :datafilter="filterData" :business="business"  :isCarsem="isCarsem" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-1 position-relative">
            <v-card-text class="pa-0">
              <SpecialInventoryPhysicalR v-if="SpecialInventoryReport == 1" :keyR="key" :datafilter="filterData"/>
              <InventoryPhysicalR v-else :key="key" :datafilter="filterData"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-3" v-if="$store.getters['rolesUser/getArrayCustomRole']([244]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-1 position-relative">
            <v-card-text class="pa-0"> 
              <WeightedAverage :win="win" :key="key"  :datafilter="filterData" />

            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-4" v-if="$store.getters['rolesUser/getArrayCustomRole']([245]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-1 position-relative">
            <v-card-text class="pa-0"> 
              <Peps :win="win" :key="key" :datafilter="filterData" />
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="tab-5" v-if="$store.getters['rolesUser/getArrayCustomRole']([246]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-1 position-relative">
            <v-card-text class="pa-0"> 
              <Ueps :win="win" :key="key" :datafilter="filterData" />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-6" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-1 position-relative">
            <v-card-text class="pa-0">
              <InventoyMinimumAndMaximum :key="key" :datafilter="filterData"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-7" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-1 position-relative">
            <v-card-text class="pa-0">
              <InventoryAdjustmentRequest :key="key" :datafilter="filterData"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-8" v-if="$store.getters['rolesUser/getArrayCustomRole']([243]) || $store.getters['rolesUser/isAdminOrManager']">
          <v-card flat class="pa-1 position-relative">
            <v-card-text class="pa-0">
              <InventoryAdjustmentCheck :key="key" :datafilter="filterData"/>
            </v-card-text>
          </v-card>
        </v-tab-item>

      </v-tabs-items>
    </v-expansion-panel-content>
    <!--product-data v-if="showProduct"
                              :key="showProduct.data.ID_Item"
                              @reloadData="reloadProducts"
                              :product="showProduct.data"
                              :win="win"
                  ></product-data-->
  </v-expansion-panel>
</template>

<script>
//import DatePicker from "../../../components/DatePicker";

//import ProductData from "@/views/inventory/mainPages/ManageProduct";
import InventoryReport from "@/views/inventory/reports/InventoryReport";
import InventoyMinimumAndMaximum from "@/views/inventory/reports/InventoyMinimumAndMaximum";
import InventoryPhysicalR from "@/views/inventory/reports/InventoryPhysicalR";
import WeightedAverage from "@/views/inventory/reports/WeightedAverage";
import Peps from "@/views/inventory/reports/Peps";    
import Ueps from "@/views/inventory/reports/Ueps";

import { mapState } from "vuex";
import SpecialInventoryPhysicalR from "../reports/SpecialInventoryPhysicalR.vue";
import SpecialInventoryReport from "../reports/SpecialInventoryReport.vue";
import InventoryAdjustmentRequest from "../reports/InventoryAdjustmentRequest.vue";
import InventoryAdjustmentCheck from "../reports/InventoryAdjustmentCheck.vue";
import {
  VUE_APP_CARSEM_ID_KEYMASTER1,
  VUE_APP_CARSEM_ID_KEYMASTER2,
  VUE_APP_CARSEM_ID_KEYMASTER3
} from "@/config";
export default {
  name: "StockReport",
  components: {
    InventoryReport,
    InventoryPhysicalR,
    InventoyMinimumAndMaximum,
    WeightedAverage,
    Peps,
    Ueps,
    SpecialInventoryPhysicalR,
    SpecialInventoryReport,
    InventoryAdjustmentRequest,
    InventoryAdjustmentCheck
},
  props: ["win"],
  data() {
    return {
      panel: 0,
      tab: "tab-1",
      key: 0,
      business: {},
      filterData:{
        Warehouses: [],
        Providers: [],
        Families: [],
        Categories: [],
        SubCategories: [],
        batches: [],
        providers: [],
      },
      SpecialInventoryReport: JSON.parse(localStorage.getItem('user')).SpecialInventoryReport,
      isCarsem: false,
    };
  },
  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
  },
  async mounted(){
    this.verifyCarsem();
    await this.getFilterData();

  },
  methods: {
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    verifyCarsem(){
      const keyMastersArray = JSON.parse( this.getCookie( "ID_KeyMaster" ) );
      const specialKeyMasters = keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER1 )  || keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER2 ) || keyMastersArray.includes( VUE_APP_CARSEM_ID_KEYMASTER3 );
      console.log('',specialKeyMasters);
      if(specialKeyMasters){
        this.isCarsem = true;
      }
      else{
        this.isCarsem = false;
      }
    },
    reload(){
      this.key++;
      this.getFilterData();

    },
    
    async getFilterData() {
      try {
        const { businessid = "", branch = "" } = JSON.parse(localStorage.getItem("user"));
        const filtersWh = { business: businessid, branch };
       

        const [families, categories, subs, warehouses, batches, providers, business] = await Promise.all([
          this.$API.itemtypes.getFamilies(),
          this.$API.itemtypes.getCategories(),
          this.$API.itemtypes.getSubs(),
          this.$API.warehouse.getWarehouses(filtersWh),
          this.$API.products.getAllBatch(),
          this.$API.providers.getProvidersHistory(filtersWh),
          this.$API.business.FindOne(businessid)
        ]);
       
        this.filterData.Families = families.map((fam) => ({name: fam.name, value: fam.id}));
        this.filterData.Categories = categories.map((cat) => ({name: cat.name, value: cat.id, parent_id: cat.id_family}));
        this.filterData.SubCategories = subs.map((sub) => ({name: sub.name, value: sub.id, parent_id: sub.id_cat}));
        this.filterData.Warehouses = warehouses.map((wh) =>({name: wh.Warehouse, value: wh.ID_Warehouse}));
        this.filterData.batches = batches.map(({ID_ItemBatch}) => ({name: ID_ItemBatch, value: ID_ItemBatch}));
        this.filterData.providers = providers.map((prov) => ({name: prov.ProviderName, value: prov.ID_Provider}));
        this.business = business;
        this.key++;
      } catch (error) {
        // Manejar errores aquí
        console.error("Error al obtener datos:", error);
      }
    }

  },
};
</script>

<style scoped>
.show .v-list-item {
  min-height: auto !important;
}

.filter-btn {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btncontinue {
  background-color: rgba(80, 80, 80, 1) !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.justified-column {
  width: 100%;
  border: 1px solid #ccc;
  /* Solo para visualizar el contenedor */
  padding: 10px;
  /* Espaciado interno */
  word-wrap: break-word;
  /* Permite el salto de palabras */
}
</style>
