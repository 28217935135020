import { render, staticRenderFns } from "./PrintMayorDailyBook.vue?vue&type=template&id=64e5072c&scoped=true"
import script from "./PrintMayorDailyBook.vue?vue&type=script&lang=js"
export * from "./PrintMayorDailyBook.vue?vue&type=script&lang=js"
import style0 from "./PrintMayorDailyBook.vue?vue&type=style&index=0&id=64e5072c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64e5072c",
  null
  
)

export default component.exports