<template>
    <v-card>
        
        <v-card-title>
            Historial de abonos y ordenes de compra a crédito
        </v-card-title>
        <v-tabs align-tabs="center">
            <v-tab key="tab1" outlined>
                <b>Historial de Abonos</b>
            </v-tab>
            <v-tab key="tab2" outlined>
                <b>Ordenes de compra con créditos pendientes o canceladas</b>
            </v-tab>

            <v-tab-item key="tab1">
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" md="6">
                            <v-autocomplete dense attach  :items="idsAcountsPayable" v-model="ID_AccountsPayable" label="ID de Abono" outlined></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn class="btn-add" @click="printOne" :disabled="!ID_AccountsPayable">
                                <v-icon>mdi-printer</v-icon>  
                            </v-btn>
                        </v-col>
                        
                    </v-row>
                    <GeneralFilter  class="background border-box" :filterEndpoint="$API.providers.getFilterPaymentsByPurchase" :search="search" 
                    @emptyFilter="getAllPaymentsByPurchase" @filtered="filterHandler">
                        <DxDataGrid  
                        :dataSource="DataTable"
                        :showBorders="true"
                        ref="dataGrid"
                        :scrolling="{ useNative: true }"
                        @row-expanded="onRowExpanded"
                        >
                            <DxColumn dataField="ID_AccountsPayablePayment" caption="ID de Pago" width="200" />
                            <DxColumn dataField="ID_PurchaseOrder" caption="ID de Orden" width="200" />
                            <DxColumn dataField="ID_PurchaseLegalInvoiceNumber" caption="Correlativo o Código de Generación" />
                            <DxColumn cell-template="cell-payment" caption="Pago Realizado" width="200" />
                            <template #cell-payment="{ data }">
                                {{ data.data.PaymentShow }}
                            </template>
                            <!-- <DxGrouping :auto-expand-all="false"/> -->
                            <DxColumn :group-index="0" data-field="ID_AccountsPayable" caption="ID de Abono" :group-cell-template="getCustomCell" />

                            <DxPager :visible="true" :show-page-size-selector="false" :show-info="false" :show-navigation-buttons="true" />
                            <DxPaging :page-size="10" />
                            
                           
                        </DxDataGrid>

                    </GeneralFilter>
                </v-card-text>
            </v-tab-item>

            <v-tab-item key="tab2">
                <v-card-text>
                    <v-row class="mx-1">
                        <v-col cols="12" md="12"  class="d-flex justify-end ">
                            <v-btn class="btn-add" @click.prevent="printAll">
                                <v-icon>mdi-printer</v-icon>  
                            </v-btn>
                        </v-col>
                    </v-row>
                    <GeneralFilter  class="background border-box" :filterEndpoint="$API.providers.getFilterPurchaseWithCredit" :search="search" @emptyFilter="getPurchaseWithCredit" @filtered="filterHandler2">
                        <DxDataGrid :dataSource="PurchaseWithCredit" :showBorders="true" :scrolling="{ useNative: true }" :column-auto-width="true">
                            <DxColumn dataField="ID_PurchaseOrder" caption="ID de Orden" width="175" />
                            <DxColumn dataField="ID_PurchaseLegalInvoiceNumber" caption="Correlativo o Código Generación" />
                            <DxColumn dataField="PurchaseOrderDate" caption="Fecha" width="100" />
                            <DxColumn dataField="days" caption="Crédito" width="100"/>
                            <DxColumn cell-template="due-date" caption="Fecha de Venc." width="120" />
                            <template #due-date="{ data }">
                                <div>
                                    {{  calculateDueDate(data.data.PurchaseOrderDate, data.data.days) }}
                                </div>
                            </template>
                            <DxColumn cell-template="cell-canceled" caption="Abonado" width="100" />
                            <template #cell-canceled="{ data }">
                                {{  Currency + ' '+ data.data.Canceled.toFixed(2) }}
                            </template>
                            <DxColumn cell-template="cell-debt" caption="Monto inicial" width="100" />
                            <template #cell-debt="{ data }">
                                {{  Currency + ' '+ data.data.PurchaseOrderTotal.toFixed(2) }}
                            </template>
                            <DxColumn cell-template="cell-pending" caption="Pendiente" width="100" />
                            <template #cell-pending="{ data }">
                                {{ Currency + ' '+data.data.PendingToPay.toFixed(2) }}
                            </template>

                            <DxPager :visible="true" :show-page-size-selector="false" :show-info="false" :show-navigation-buttons="true" />
                            <DxPaging :page-size="10" />
                        </DxDataGrid>
                    </GeneralFilter>
                </v-card-text>
            </v-tab-item>
        </v-tabs>
        <div v-for="win in windowsList" :key="win.key">
             <component :is="win.component"  v-bind="win.props" />
        </div>
        <alert
            v-model="deleteConfirmDialog"
            type="confirm"
            title="Eliminar"
            :message="`¿Eliminar el abono seleccionado?`"
            @action="deleteReceipt"
        >
        </alert>
        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        >
        </alerts>
    </v-card>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import GeneralFilter from '@/components/GeneralFilter.vue';
    import {DxPager, DxPaging,DxColumn, DxDataGrid,DxGrouping } from 'devextreme-vue/data-grid';
    import PrintAcountPayableID from '@/components/ReportsAndPrintings/PrintAcountPayableID.vue';
    import PrintAcountPayable from '@/components/ReportsAndPrintings/PrintAcountPayable.vue';
    import Alerts from "@/components/Alerts";
    import moment from 'moment';

    export default {
        name: "AccountsPayableHistory",
        components: {
            DxPager,
            DxPaging,
            DxColumn,
            DxDataGrid,
            GeneralFilter,
            DxGrouping,
            PrintAcountPayable,
            Alerts
        },
        props: ['win', 'Provider'],
        data() {
            return {
                deleteConfirmDialog: false,
                DataTable: [],
                PurchaseWithCredit: [],
                panel: 0,
                tab: null,
                key: 0,
                search: {
                    ID_Provider: this.Provider.ID_Provider
                },
                Currency: JSON.parse(localStorage.getItem('branch')).Currency,
                windowsList: [],
                print: {
                    show : false,
                    header: {},
                    data: []
                },
                idsAcountsPayable: [],
                ID_AccountsPayable: null,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                }
            }
        },
        computed:{  
            
        },
        methods: {
            /*loadData(){
                this.getAllPaymentsByPurchase()
                this.getInvoiceWithCredit()
            },*/
            closeAlert() {
                this.alert.show = false;
            },
            printOne(){
                this.print.header = {
                    title: 'Recibo de compras',
                    subTitle: 'ID de Abono: ' + this.ID_AccountsPayable,
                    busniessName: JSON.parse(localStorage.getItem('user')).business,
                    date: this.DataTable.find((item)=> item.ID_Receipt == this.ID_AccountsPayable || item.ID_AccountsPayable == this.ID_AccountsPayable).PaymentDate,
                    customer: this.Provider,
                    currentDate: moment().format('DD/MM/YYYY'),
                },
                this.print.data.body = this.DataTable.filter((item)=> item.ID_Receipt == this.ID_AccountsPayable || item.ID_AccountsPayable == this.ID_AccountsPayable);
                 const TotalPayment = this.print.data.body.reduce((a, b)=> a + b.Payment, 0);
                this.print.data.body.forEach((item, index)=>{
                    item.index = index + 1;
                })
                this.print.show = true;
                this.print.footer = {
                    TotalPayment: TotalPayment.toFixed(2),
                    TotalPaymentShow: JSON.parse(localStorage.getItem('branch')).Currency + " " + TotalPayment.toFixed(2)
                }
                this.windowsList.push({
                component: PrintAcountPayableID,
                props: {
                    dataProps: this.print,                
                },
                key: new Date().getTime(),
            });
            },
            findOldDate(data){
                
                // Extraer todas las fechas en formato de cadena
                const datesAsString = data.map((item) => item.PurchaseOrderDate);
    
                // Convertir las cadenas en objetos Date
                const datesAsDate = datesAsString.map((dateStr) => {
                    const [day, month, year] = dateStr.split('/').map(Number);
                    return new Date(year, month - 1, day); // Restar 1 al mes porque en JavaScript los meses comienzan desde 0
                });
    
                // Encontrar la fecha más antigua
                const oldDate = datesAsDate.reduce((a, b) => (a < b ? a : b));
    
                return oldDate.toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit"
                });
            },
            printAll(){
                this.print.header = {
                    title: 'Recibo de ingresos',
                    subTitle: 'Estado de cuenta',
                    busniessName: JSON.parse(localStorage.getItem('user')).business,
                    date:  this.findOldDate(this.PurchaseWithCredit),
                    customer: this.Provider,
                    currentDate: moment().format('DD/MM/YYYY')
                }
                const Tansactions = [];
                Tansactions.push({
                    Note: 'Saldo Inicial',
                    ABO: 0,
                    Cred: 0,
                    Balance: 0
                })
               
                this.PurchaseWithCredit.forEach((item)=>{
                    item.Note = 'Cargo por nuevo credito a factura ' +  this.calculateDueDate(item.PurchaseOrderDate, item.days)
                    Tansactions.push({ ...item, Cred: item.PurchaseOrderTotal, Balance: 0, ABO: 0  });
                    const data = this.DataTable.filter((item2)=> item2.ID_PurchaseOrder == item.ID_PurchaseOrder)
                    if(data.length > 0){
                        data.forEach((item3)=>{
                            item3.ABO = item3.Payment;
                            item3.Cred = 0;
                            item3.Balance = 0;
                            Tansactions.push(item3);
                        })
                        
                    }
                    
                });
                const Currency = JSON.parse(localStorage.getItem('branch')).Currency;
                this.print.data.body = Tansactions.map((item, index)=>{
                    item.index = index + 1;
                    item.ABOShow = Currency + " " + item.ABO.toFixed(2);
                    item.CredShow = Currency + " " + item.Cred.toFixed(2);
                    //calcular el balance
                    if(index == 0){
                        item.Balance =  0
                    }else{
                        item.Balance = Tansactions[index - 1].Balance + item.ABO - item.Cred
                    }

                    item.BalanceShow = Currency + " " + item.Balance.toFixed(2);
                    return item
                });
                this.print.show = true;
                this.print.footer = {

                   //last balance of transactions 
                    TotalPaymentShow: Tansactions[Tansactions.length - 1].BalanceShow,
                }
                this.windowsList.push({
                    component: PrintAcountPayable,
                    props: {
                        dataProps: this.print,                
                    },
                    key: new Date().getTime(),
                });

            },
            handlerAcountsReceivable(data = []){
                this.idsAcountsPayable = [];
                data.forEach((item)=>{
                    if(item.ID_Receipt != null){
                        this.idsAcountsPayable.find((id) => 
                        id == item.ID_Receipt) 
                            ? null 
                            : this.idsAcountsPayable.push(item.ID_Receipt)
                    }else{
                        this.idsAcountsPayable.find((id) => 
                        id == item.ID_AccountsPayable) 
                            ? null 
                            : this.idsAcountsPayable.push(item.ID_AccountsPayable)
                    }
                })
            },
            filterHandler(data){   
                this.handlerAcountsReceivable(data)
                this.DataTable = data.map((item)=>{
                    return {
                        ...item,
                        PaymentShow: this.Currency + " " + item.Payment.toFixed(2)
                    }
                })
            
            },
            filterHandler2(data){
                this.PurchaseWithCredit = data
            },
            getAllPaymentsByPurchase(){
                
                this.$API.providers.getAllPaymentsByPurchase(this.search)
                .then((res)=>{
                    this.handlerAcountsReceivable(res)
                    this.DataTable = res.map((item)=>{
                    return {
                        ...item,
                        PaymentShow: this.Currency + " " + item.Payment.toFixed(2)
                    }
                })
                })
                .catch((err)=>{
                    console.log(err);
                })
            },
            // getCustomCell(rowData) {
            //     return (rowData.ID_Receipt != null ? rowData.ID_Receipt : rowData.ID_AccountsPayable) +  " - Fecha de Abono: " + rowData.PaymentDate + " - Total de Abono: " + rowData.TotalPayment
            // },
            getCustomCell(cellElement, cellInfo) {
                const data = cellInfo.data.items[0];
                const groupContent = "ID Abono: " + (data.ID_Receipt != null ? data.ID_Receipt : data.ID_AccountsPayable) +  
                             " - Fecha de Abono: " + data.PaymentDate + 
                             " - Total de Abono: $" + parseFloat(data.TotalPayment).toFixed(2) + " ";
                
                const span = document.createElement('span');
                span.textContent = groupContent;

                const button = document.createElement('button');
                button.textContent = 'Eliminar';
                button.setAttribute('data-group-id', data.ID_Receipt || data.ID_AccountsPayable);

                button.style.backgroundColor = '#e74c3c'; /* Rojo brillante */
                button.style.color = 'white'; /* Texto blanco */
                button.style.border = 'none'; /* Sin borde */
                button.style.borderRadius = '4px'; /* Bordes redondeados */
                button.style.padding = '8px 16px'; /* Espaciado interno */
                button.style.fontSize = '14px'; /* Tamaño de fuente */
                button.style.cursor = 'pointer'; /* Cambia el cursor al pasar sobre el botón */
                button.style.transition = 'background-color 0.3s ease';
                button.style.float = 'right'

                cellElement.appendChild(span);
                cellElement.appendChild(button);

                button.addEventListener('click', () => {
                    this.deleteGroup(data.ID_Receipt || data.ID_AccountsPayable);
                });
            }, 
            deleteGroup(id) {
                this.ReceiptToDelete = id
                this.deleteConfirmDialog = true;
            },
            deleteReceipt() {
                this.deleteConfirmDialog = false;
                this.$API.providers.deleteReceipt({ ID_Receipt: this.ReceiptToDelete })
                    .then(response => {
                        if (response.status == "OK") {
                            this.getAllPaymentsByPurchase()
                            this.showAlert("success", "Éxito", "Se ha eliminado el abono seleccionado.")
                        }
                        else {
                            this.showAlert("warning", "Advertencia", "Ocurrió un error al intentar eliminar el abono seleccionado.")
                        }
                    })
                    .catch(() => {
                        this.showAlert("warning", "Advertencia", "Ocurrió un error al intentar eliminar el abono seleccionado.")
                    })
            },
            getPurchaseWithCredit(){
                this.$API.providers.getPurchaseWithCredit(this.search)
                .then((res) =>{
                    this.PurchaseWithCredit = res
                })
                .catch((err) =>{
                    console.log(err)
                })
            },
            onRowExpanded(e) {
                console.log(e)
            },
            calculateDueDate(originDate, invervalDay){
                var parts = originDate.split('/');
                var day = parseInt(parts[0], 10);
                var month = parseInt(parts[1] - 1, 10);
                var year = parseInt(parts[2], 10);

                var days = parseInt(invervalDay, 10);

                var date = new Date(year, month, day);

                date.setDate(date.getDate() + days);

                //var actualDate = new Date();

                var newDay = date.getDate().toString().padStart(2, '0');
                var newMonth = (date.getMonth() + 1).toString().padStart(2, '0');
                var newYear = date.getFullYear();
                var newDate = newDay + '/' + newMonth + '/' + newYear;

                return newDate
            } ,
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
        },
        mounted(){
            this.getAllPaymentsByPurchase();
            this.getPurchaseWithCredit();
        }

    }
</script>

<style scoped>

</style>