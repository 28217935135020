import axios from "./axios";

export default {
    /**
     * Get Warehouse
     * @api {Get} managewarehouse/
     */
    getWarehouses(params) {
        const options = {
            method: 'GET',
            url: 'managewarehouse/warehouses',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getWarehousesHistory(params) {
        const options = {
            method: 'GET',
            url: 'managewarehouse/warehouses/history',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getWarehousesFiltered(params) {
        const options = {
            method: 'GET',
            url: 'managewarehouse/warehouses/filtered',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addWarehouse(data) {
        const options = {
            method: 'POST',
            url: 'managewarehouse/addwarehouse',
            headers: {'content-type': 'application/json'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    //BATCHS
    getBatchs(params) {
        const options = {
            method: 'GET',
            url: 'managewarehouse/batchs',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addBatch(data) {
        const options = {
            method: 'POST',
            url: 'managewarehouse/addbatch',
            headers: {'content-type': 'application/json'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateBatch(data) {
        const options = {
            method: 'POST',
            url: 'managewarehouse/updatebatch',
            headers: {'content-type': 'application/json'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteBatch(data) {
        const options = {
            method: 'POST',
            url: 'managewarehouse/deletebatch',
            headers: {'content-type': 'application/json'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    //ACCOUNTING GROUPS
    getAccountingGroups(params) {
        const options = {
            method: 'GET',
            url: 'managewarehouse/accountinggroups',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    addAccountingGroup(data) {
        const options = {
            method: 'POST',
            url: 'managewarehouse/addaccountinggroup',
            headers: {'content-type': 'application/json'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    updateAccountingGroup(data) {
        const options = {
            method: 'POST',
            url: 'managewarehouse/updateaccountinggroup',
            headers: {'content-type': 'application/json'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    deleteAccountingGroup(data) {
        const options = {
            method: 'POST',
            url: 'managewarehouse/deleteaccountinggroup',
            headers: {'content-type': 'application/json'},
            data: data
        };

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    changueWarehouseSelected(data){
        const options = {
            method: 'POST',
            url: 'managewarehouse/warehouseselected',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {reject(error.message)})
        })

    },

    updateWarehouseSelected(data){
        const options = {
            method: 'POST',
            url: 'managewarehouse/changueWarehouse',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {reject(error.message)})
        })

    },

    updateHospitalWarehouse(data){
        const options = {
            method: 'POST',
            url: 'managewarehouse/changeHospitalWarehouse',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {reject(error.message)})
        })
    },
    
    updatePatientWarehouse(data){
        const options = {
            method: 'POST',
            url: 'managewarehouse/changePatientWarehouse',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {reject(error.message)})
        })
    },
    updateWarehouseBranch(data){
        const options = {
            method: 'POST',
            url: 'managewarehouse/updateWarehouseBranch',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {reject(error.message)})
        })
    },

    logicalDeleteWarehouse(id) {
        const options = {
            method: "PUT",
            url: `managewarehouse/logicaldelete/${id}`,
            headers: { "content-type": "application/json" },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },

}