import axios from "./axios";

export default {
    /**
     * Add Patient
     * @api {POST} /business
     */
    getAccountLevels() {
        const options = {
            method: 'GET',
            url: '/accountlevel',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    createAccountLevels(data) {
        const options = {
            method: 'POST',
            url: '/accountlevel/',
            headers: {'content-type': 'application/json'},
            data: { data },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    deleteAccountLevels(id_level) {
        const options = {
            method: 'DELETE',
            url: '/accountlevel/',
            headers: {'content-type': 'application/json'},
            params: { id_level }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getOneAccountLevel( id_level ) {
        const options = {
            method: 'GET',
            url: '/accountlevel/',
            headers: {
                'content-type': 'application/json',
            },
            params: {
                id_level
            }
        }

        return new Promise( ( resolve, reject ) => {
            axios( options )
                .then( response => {
                    resolve( response.data );
                } )
                .catch( error => {
                    reject( error );
                } );
        } );
    },
    updateAccountLevels(id_level, toUpdateValues) {
        const options = {
            method: 'DELETE',
            url: '/accountlevel/',
            headers: {'content-type': 'application/json'},
            params: { id_level },
            data: { toUpdateValues }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAcountingHeader(){
        const options = {
            method: 'GET',
            url: '/accountingentryheader',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getLastAcountingCorrelative(){
        const options = {
            method: 'GET',
            url: '/accountingentryheader/getLastAcountingCorrelative',
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAcountingPeriodClosed(params){
        const options = {
            method: 'GET',
            url: '/accountingentryheader/getAcountingPeriodClosed',
            headers: {'content-type': 'application/json'},
            params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAcountingHeaderFiltered(params){
        const options = {
            method: 'GET',
            url: '/accountingentryheader/list/filtered',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    getAcountingHeaderFilteredBydate(params){
        const options = {
            method: 'GET',
            url: '/accountingentryheader/list/filteredbydate',
            headers: {'content-type': 'application/json'},
            params: params
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    if(response === undefined){
                        reject('No se obtuvo respuesta del servidor');
                    }
                    else if(response.data.success){
                        resolve(response.data);
                    }else{
                        reject(response.data);
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}