<template>
    <v-card flat class="position-relative white pa-4 rounded-md"  >
        <block v-if="loading"></block>
        <v-row class="my-4" v-if="data">
            <v-col cols="6">
                <v-btn  v-if="$store.getters['rolesUser/getCustomRole'](122)"  class="btn-add"  @click="() => showCreateModal = true">
                    <v-icon class="mr-2">mdi-plus</v-icon>
                    Crear comprobante de retención manual
                </v-btn>
            </v-col>
        </v-row> 
        <div v-if="$store.getters['rolesUser/getCustomRole'](121)">
            <date-filter class="item"  @filtered-data="dateFilterHandler"   v-if="!loading"
                :dataToFilter="crList" nameOfDate="Hisotrial de retencion"  :search="search"
                :filterEndpoint="$API.orders.getAllDataCrWithDate" :isFilteredByOutsource="isFiltered"/>
            <GeneralFilter
            class="background border-box"
            entity="lista"
            :filterEndpoint="$API.orders.getDataCrFiltered"
            :search="search"
            @emptyFilter="data ? getDataCr() : getAllDataCr()"
            @filtered="filterHandler">
                <DxDataGrid class="isScrolledByGeneralFilter" :data-source="crList" @exporting="onExporting" :paging="{ enabled: false }" style="max-height: 600px">
                    <DxExport :enabled="true" />
                    <DxColumn v-if="$store.getters['rolesUser/getCustomRole'](123)" :width="80" cell-template="show-template" caption=""></DxColumn>
                    <DxColumn data-field="ID_CR" caption="ID"></DxColumn>
                    <DxColumn data-field="IdentificacionFecEmi" caption="Fecha de emisión"></DxColumn>
                    <DxColumn data-field="IdentificacionHorEmi" caption="Hora de emisión"></DxColumn>
                    <DxColumn data-field="ReceptorNrc" caption="NRC Receptor"></DxColumn>
                    <DxColumn data-field="ReceptorNombre" caption="Nombre Receptor"></DxColumn>
                    <DxColumn data-field="IdentificacionCodigoGeneracion" caption="Código de Generación"></DxColumn>
                    <DxColumn data-field="FeMhEstado" caption="Estado"></DxColumn>
                    <DxColumn v-if="$store.getters['rolesUser/getCustomRole'](124)" cell-template="show-options" caption=""></DxColumn>
                    <template #show-template="{ data }">
                        <a href="#" class="mx-1" @click.prevent.stop="displayPrinter(data)">
                            <v-icon>mdi-printer</v-icon>
                        </a>
                    </template>
                    <template #show-options="{ data }">
                        <v-btn v-if="data.data.FeMhEstado == 'PROCESADO'" class="mx-1 btndelete" @click.prevent.stop="showInvalidAlert = true; dataCrHeader = data.data">
                            Invalidar
                        </v-btn>
                    </template>
                </DxDataGrid>
            </GeneralFilter>
        </div>

        <!-- Modal -->
        <v-dialog v-model="showCreateModal" scrollable :width="$vuetify.breakpoint.smAndDown ? '90%':'80%'">
            <CreateSalesReceipt :win="data" @close-modal="showCreateModal = false" @reloadData="getDataCr()" />
        </v-dialog>
        <!-- Modal -->

        <!-- Modal -->
        <v-dialog v-model="showDataModal" scrollable width="75%">
            <!-- <TransferDetails :transferData="transferData" @close-modal="showDataModal = false" /> -->
        </v-dialog>
        <!-- Modal -->

        
        <PrintReceipWithHolding :data="print"></PrintReceipWithHolding>

        <v-dialog v-model="showInvalidAlert" max-width="500">
            <v-card v-if="showInvalidAlert" class="text-center">

            <template>
                <v-card-title class="text-h5">
                ¿Esta seguro de invalidar el comprobante de retención?

                <v-text-field outlined dense v-model="invalidForm.motivoInvalid" :rules="motivoInvalidRules"
                    label="Motivo de invalidación"></v-text-field>

                <v-select outlined dense attach v-model="invalidForm.tipo" :items="invalidForm.tipoInvalid"
                    item-text="value" item-value="id" label="Seleccione el tipo de invalidación">
                </v-select>
                </v-card-title>

                <v-btn class="ma-2" large color="info" @click="invalidCr()">
                Invalidar
                </v-btn>

                <v-btn class="ma-2" large color="error" @click="showInvalidAlert = false; dataCrHeader = {}">
                Cancelar
                </v-btn>
            </template>

            </v-card>
        </v-dialog>

        <alerts style="z-index: 20000 !important;"
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
            >
        </alerts>
    </v-card>
</template>

<script>
import GeneralFilter from '../../../components/GeneralFilter.vue';
import CreateSalesReceipt from '../CreateSalesReceipt.vue';
import { DxDataGrid, DxColumn, DxExport } from 'devextreme-vue/data-grid';
import PrintReceipWithHolding from '../../../components/ReportsAndPrintings/PrintReceipWithHolding.vue';
import { printToExcel } from "@/helpers/printToexcel";
import Block from "@/components/Block";
import Alerts from "@/components/Alerts";
import DateFilter from '@/components/DateFilter.vue';


export default {
    name: "ReceiptWithholdingHistory",
    props: ['data'],
    components: { DxDataGrid, DxColumn, DxExport, CreateSalesReceipt, PrintReceipWithHolding, GeneralFilter, Block, Alerts, DateFilter },
    data() {
        return {
            form: {},
            showFilter: false,
            showDataModal: false,
            showCreateModal: false,
            crList: null,
            isFiltered: false,
            print: {
                show: false
            },
            search:{
                filter: null
            },
            showInvalidAlert: false,
            invalidForm: {
                motivoInvalid: "",
                tipo: 2,
                tipoInvalid: [
                    { id: 2, value: "Rescindir de la operación realizada." }
                ]
            },
            dataCrHeader: {},
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            loading: false,
        }
    },
    watch: {
        'showModal'() {
            if (!this.showModal) {
                this.closeDialog()
            }
        },
    },
    mounted() {
        this.loading = true
        if(this.data != null || this.data != undefined)
        this.getDataCr()
        else
        this.getAllDataCr()
    },
    methods: {
        dateFilterHandler(response) {
            this.crList = response 
        },
        closeAlert() {
            this.alert.show = false;
            if (this.alert.options == "reloadData") {
                this.getDataCr()
            }
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
        async displayPrinter(data){
            try {

                const result = await this.$API.orders.getDataCrDetail({ ID_CR: data.data.ID_CR })
                this.print = { data: data.data, body: result, show: true };
                
            } catch (error) {
                console.log(error);
            }
        },  
        onExporting(e){
            printToExcel(e, 'Historial Comprobantes De Retención')
        },
        getDataCr(){
            this.loading = true
            this.search.ID_Provider = this.data.ID_Provider
            this.$API.orders.getDataCrHistory({ ID_Provider: this.data.ID_Provider }).then(response => {
                this.crList = response
                this.loading = false
            })
            .catch((err) => {
                this.loading = false
                console.log(err);
            })
        },
        getAllDataCr(){
            this.loading = true
            this.$API.orders.getAllDataCr().then(response => {
                this.crList = response
                this.loading = false
            })
            .catch((err) => {
                this.loading = false
                console.log(err);
            })
        },
        filterHandler(response) {
            this.crList = response 
        },
        async invalidCr(){
            try{
                if(this.invalidForm.tipo == 2){
                    const data = this.dataCrHeader
                    this.dataCrHeader = {}
                    this.showInvalidAlert = false;
                    this.loading = true

                    this.$API.orders.invalidCr({ dataCrHeader: data, invalidForm: this.invalidForm, user: JSON.parse(localStorage.getItem("user")).id })
                        .then(response => {
                            this.loading = false
                            if (response.estado == "PROCESADO") {
                                let mensaje = "Se ha realizado y procesado la invalidación <br/><br/>";
                                mensaje += "Estado: " + response.estado + "<br/>";
                                mensaje += "Mensaje del DTE: <br/> - " + response.descripcionMsg + "<br/>";
                                mensaje += "Observaciones DTE: <br/>"
                                if (response.observaciones.length == 0) {
                                    mensaje += "- Ninguna observación <br/>"
                                }
                                else {
                                    for (let i = 0; i < response.observaciones.length; i++) {
                                    mensaje += "- " + response.observaciones[i] + "<br/>"
                                    }
                                }

                                this.showAlert(
                                    "successHtml",
                                    "Invalidación realizada",
                                    mensaje,
                                    "reloadData"
                                );
                            }
                            else {
                                let mensaje = "Se ha rechazado la invalidación, tomar en cuenta las observaciones del Ministerio de Hacienda y realizarla de nuevo.<br/><br/>";
                                mensaje += "Estado: " + response.estado + "<br/>";
                                mensaje += "Mensaje del DTE: <br/> - " + response.descripcionMsg + "<br/>";
                                mensaje += "Observaciones DTE: <br/>"
                                if (response.observaciones.length == 0) {
                                    mensaje += "- Ninguna observación <br/>"
                                }
                                else {
                                    for (let i = 0; i < response.observaciones.length; i++) {
                                        mensaje += "- " + response.observaciones[i] + "<br/>"
                                    }
                                }
                                this.showAlert(
                                    "successHtml",
                                    "Invalidación rechazada",
                                    mensaje
                                );
                            }
                        })
                        .catch((err) => {
                            let message = "Ocurrio un error crítico al realizar la invalidación. Por favor intentelo nuevamente. Luego de 3 intentos sin éxito contáctese con el administrador. "
                            message += "" + JSON.stringify(err)
                            this.showAlert(
                                "warning",
                                "Advertencia",
                                message
                            )
                        })
                }
            }
            catch(err) {
                console.log(err);
            }
        }
    }
}
</script>

<style scoped>
.close-icon {
    color: red;
}

.filter-btn {
    position: absolute;
    top: 15px;
    left: 0px;
    z-index: 9;
}

.btnclean {
    background-color: #F29D35 !important;
    color: white;
}

.border-box {
    border-radius: 15px !important;
}

.background {
  background: white !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}
</style>
